import {
  STORE_METRICS_DATA,
  METRICS_LOADING_STATUS,
} from '../actions/action-types';

const initialState: MetricsData = {
  assetData: null,
  historyData: null,
  eventsReference: null,
  eventsData: null,
  isLoading: true,
};

interface MetricsData {
  assetData: any;
  historyData: any;
  eventsReference: any;
  eventsData: any;
  isLoading: any;
}

export const assetFeatureKey = 'MetricsData';

export function metricsDataReducer(state = initialState, action): MetricsData {
  switch (action.type) {
    case STORE_METRICS_DATA:
      return {
        ...state,
        assetData: action.payload.assetData,
        historyData: action.payload.historyData,
        eventsReference: action.payload.eventsReference,
        eventsData: action.payload.eventsData,
        isLoading: action.payload.isLoading,
      };

    case METRICS_LOADING_STATUS:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };

    default: {
      return state;
    }
  }
}
