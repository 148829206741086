import {
  STORE_GEOFENCE_DATA,
  ADD_GEOFENCE,
  DELETE_GEOFENCE,
  EDIT_GEOFENCE,
  GET_GEOFENCE_ERROR,
} from '../actions/action-types';

const initialState: GeofenceData = {
  data: {},
  hasError: false,
};

interface GeofenceData {
  data: DataTypes | any;
  hasError: boolean;
}

interface DataTypes {
  tolls: any[];
  geofences: any[];
}

export function geofenceReducer(state = initialState, action): GeofenceData {
  switch (action.type) {
    case STORE_GEOFENCE_DATA:
      return {
        ...state,
        data: action.payload,
        hasError: false,
      };

    case GET_GEOFENCE_ERROR:
      return {
        ...state,
        data: {},
        hasError: true,
      };

    case ADD_GEOFENCE:
      return {
        ...state,
        data: {
          ...state.data,
          geofences: [...state.data.geofences, ...action.payload],
        },
      };

    case DELETE_GEOFENCE:
      const newGenfeceList = [
        ...state.data.geofences.filter((g) => g.id !== action.payload),
      ];
      return {
        ...state,
        data: {
          geofences: newGenfeceList,
          tolls: state.data.tolls,
        },
      };

    case EDIT_GEOFENCE:
      const type = `${action.payload.type.toLowerCase()}s`;
      const dataType = [...state.data[type]];
      const newDataType = dataType.map((r) => {
        if (r.id === action.payload.id) {
          return action.payload;
        }
        return r;
      });
      state.data[type] = newDataType;

      return {
        ...state,
      };

    default: {
      return state;
    }
  }
}
