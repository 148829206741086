import { Injectable } from '@angular/core';
import { AppHttpService } from '@app/shared/services/app-http.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    catchError,
    filter,
    map,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../appState';
import { SELECT_SECURITY_GROUP } from '../selectors/selectors';
import {
    loadSecurityGroupData,
    loadSecurityGroupFailure,
    loadSecurityGroupSuccess,
} from '../actions/security-groups.action';

@Injectable()
export class SecurityGroupEffects {
  constructor(
    private actions$: Actions,
    private appHttpService: AppHttpService,
    private store: Store<AppState>
  ) {}

  loadSecurityGroupData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSecurityGroupData),
      withLatestFrom(this.store.select(SELECT_SECURITY_GROUP)),
      filter(
        ([_action, securityGroupsData]) => !securityGroupsData?.data?.length
      ),
      switchMap(([_action, _securityGroupsData]) => {
        return this.appHttpService.getSecurityGroups().pipe(
          map((data) => {
            return loadSecurityGroupSuccess({ payload: data });
          }),
          catchError((error) => of(loadSecurityGroupFailure({ error })))
        );
      })
    )
  );
}
