import {
  STORE_EVENT_REFERENCE,
  STORE_ASSETS_SCHEMA_REFERENCE,
  STORE_DEVICES_SCHEMA_REFERENCE,
  STORE_GLOBAL_SCOPE_SCHEMA_REFERENCE,
} from '../actions/action-types';

const initialState: ReferenceData = {
  events: null,
  assetsSchema: null,
  devicesSchema: null,
  globalScopeSchema: null,
};

interface ReferenceData {
  events: any;
  assetsSchema: any;
  devicesSchema: any;
  globalScopeSchema: any;
}

export function referenceReducer(state = initialState, action): ReferenceData {
  switch (action.type) {
    case STORE_EVENT_REFERENCE:
      return {
        ...state,
        events: action.payload,
      };

    case STORE_ASSETS_SCHEMA_REFERENCE:
      return {
        ...state,
        assetsSchema: action.payload,
      };

    case STORE_DEVICES_SCHEMA_REFERENCE:
      return {
        ...state,
        devicesSchema: action.payload,
      };

    case STORE_GLOBAL_SCOPE_SCHEMA_REFERENCE:
      return {
        ...state,
        globalScopeSchema: action.payload,
      };

    default: {
      return state;
    }
  }
}
