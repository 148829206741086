import { createAction, props } from '@ngrx/store';
import {
  LOAD_DASHBOARD,
  LOAD_DASHBOARD_ERROR,
  STORE_DASHBOARDS,
} from './action-types';

export const loadDashboardData = createAction(LOAD_DASHBOARD);

export const loadDashboardsSuccess = createAction(
  STORE_DASHBOARDS,
  props<{ payload: any[] }>()
);

export const loadDashboardsFailure = createAction(
  LOAD_DASHBOARD_ERROR,
  props<{ error: any }>()
);
