import { SELECT_HIERARCHIES_DATA } from '../selectors/selectors';
import { Injectable } from '@angular/core';
import { AppHttpService } from '@app/shared/services/app-http.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../appState';
import {
  loadHierarchiesData,
  loadHierarchiesDataSuccess,
  loadHierarchiesDataFailure,
} from '../actions/hierarchies.action';

@Injectable()
export class HierarchiesDataEffects {
  constructor(
    public actions$: Actions,
    private appHttpService: AppHttpService,
    private store: Store<AppState>
  ) {}

  HierarchiesData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadHierarchiesData),
      withLatestFrom(this.store.select(SELECT_HIERARCHIES_DATA)),
      filter(([_action, hierarchies]) => {
        return hierarchies === null;
      }),
      switchMap(([_action, _hierarchiesData]) =>
        this.appHttpService.getAssetFilterHierarchies().pipe(
          map((data) => loadHierarchiesDataSuccess({ payload: data })),
          catchError((error) => of(loadHierarchiesDataFailure({ error })))
        )
      )
    )
  );
}
