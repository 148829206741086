import {
  ADD_WORKING_HOURS,
  DELETE_WORKING_HOURS,
} from './../actions/action-types';
import {
  WORKING_HOURS_ERROR,
  WORKING_HOURS_LOADING,
  STORE_WORKING_HOURS,
  EDIT_WORKING_HOURS,
} from '../actions/action-types';

const initialState: WorkingHoursDefinition = {
  isLoading: false,
  hasError: false,
  data: null,
};

interface WorkingHoursDefinition {
  isLoading: boolean;
  hasError: boolean;
  data: any;
}

export function workingHoursReducer(state = initialState, action): any {
  switch (action.type) {
    case WORKING_HOURS_LOADING:
      return {
        isLoading: true,
        hasError: false,
        data: null,
      };

    case STORE_WORKING_HOURS:
      return {
        isLoading: false,
        hasError: false,
        data: action.payload,
      };

    case WORKING_HOURS_ERROR:
      return {
        isLoading: false,
        hasError: true,
        data: null,
      };

    case DELETE_WORKING_HOURS:
      return {
        isLoading: false,
        hasError: false,
        data: state.data.filter((g) => g?.id !== action?.payload?.id),
      };

    case EDIT_WORKING_HOURS:
      const index = state.data.findIndex((p) => p.id === action?.payload?.id);
      state.data[index] = action?.payload?.data;
      return {
        isLoading: false,
        hasError: false,
        data: state.data,
      };

    case ADD_WORKING_HOURS:
      const newData = [...state.data, ...action.payload];
      return {
        isLoading: false,
        hasError: false,
        data: newData,
      };

    default: {
      return state;
    }
  }
}
