import { STORE_ASSETS_MASTER_DATA, UPDATE_ASSETS_MASTER_DATA } from '../actions/action-types';

const initialState = null;

export function assetsMasterDataReducer(state = initialState, action): any {
  switch (action.type) {
    case STORE_ASSETS_MASTER_DATA:
      return {
      ...state,
      data: action.payload
    }
    case UPDATE_ASSETS_MASTER_DATA:
      state.data.value = [...state.data.value , ...action.payload.value];
      state.data.key = action.payload.key
      return {
      ...state,
    }
    default: {
      return state;
    }
  }
}
