import { STORE_SERVICING_DATA } from '../actions/action-types';

const initialState = null;

export function servicingDataReducer(state = initialState, action): any {
  switch (action.type) {
    case STORE_SERVICING_DATA:
      return {
      ...state,
      data: action.payload
    }
    default: {
      return state;
    }
  }
}
