import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { reducers } from '../store/reducers';
import { deviceReducer, deviceFeatureKey } from './reducers/device.reducer';
import { environmentModules } from '../../../environments/environments.module';
import { reportsReducer } from './reducers/reports.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ReportEffects } from './effects/reports.effect';
import { dashboardsReducer } from './reducers/dashboards.reducer';
import { DashboardEffects } from './effects/dashboards.effect';
import { SYSTEM_OBJECT } from '../schemas/enums/system-object.enum';
import { platformHealthReducer } from './reducers/platform-health.reducer';
import { PlatformHealthDataEffects } from './effects/platformHealthData.effect';
import { CounterEffects } from './effects/counters.effect';
import { countersReducer } from './reducers/counters.reducer';
import { usersReducer } from './reducers/users.reducer';

import { RulesEffects } from './effects/rules.effect';
import { rulesReducer } from './reducers/rules.reducer';
import { geofenceReducer } from './reducers/geofence.reducer';
import { GeofenceEffects } from './effects/geofence.effect';
import { AssetGroupEffects } from './effects/groups.effect';
import { assetGroupReducer } from './reducers/asset-group.reducer';
import { SitesEffects } from './effects/sites.effect';
import { sitesReducer } from './reducers/sites.reducer';
import { securityGroupReducer } from './reducers/security-group.reducer';
import { SecurityGroupEffects } from './effects/security-groups.effect';
import { DeviceEffects } from './effects/devices.effect';
import { inventoryDeviceReducer } from './reducers/inventory-device.reducer';
import { AssetInventoryEffects } from './effects/asset.effect';
import { inventoryAssetReducer } from './reducers/inventory-asset.reducer';
import { peopleReducer } from './reducers/people.reducer';
import { PeopleEffects } from './effects/people.effect';
import { workingHoursReducer } from './reducers/working-hours.reducer';
import { WorkingHoursEffects } from './effects/working-hours.effect';
import { AssetsMasterDataEffects } from './effects/assets-master.effect';
import { widgetReducer } from './entities/widgets-entity/widgets-entity.reducer';
import { assetsMasterDataReducer } from './reducers/assets-master-data.reducer';
import { ServicingDataEffects } from './effects/servicing.effect';
import { servicingDataReducer } from './reducers/servicing-data.reducer';
import { AssetSharingProfilesEffects } from './effects/asset-sharing-profiles.effect';
import { assetSharingProfilesReducer } from './reducers/asset-sharing-profiles.reducer';
import { hierarchiesDataReducer } from './reducers/hierarchies-data.reducer';
import { HierarchiesDataEffects } from './effects/hierarchies.effect';
import { inventoryReducer } from './reducers/inventory.reducer';
import { InventoryEffects } from './effects/inventory.effect';
import { systemEventsReducer } from './reducers/system-events.reducer';
import { scheduledReportsReducer } from './reducers/scheduled-reports.reducer';
import { ScheduledReportsEffects } from './effects/scheduled-reports.effect';
import { AssetEffects } from './entities/assets-entity/assets-entity.effect';
import { assetEntityReducer } from './entities/assets-entity/assets-entity.reducer';

@NgModule({
  imports: [
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    EffectsModule.forRoot([
      ReportEffects,
      ScheduledReportsEffects,
      DashboardEffects,
      PlatformHealthDataEffects,
      CounterEffects,
      RulesEffects,
      GeofenceEffects,
      AssetGroupEffects,
      SitesEffects,
      SecurityGroupEffects,
      DeviceEffects,
      AssetInventoryEffects,
      PeopleEffects,
      WorkingHoursEffects,
      AssetsMasterDataEffects,
      ServicingDataEffects,
      AssetSharingProfilesEffects,
      HierarchiesDataEffects,
      InventoryEffects,
      AssetEffects,
    ]),
    StoreModule.forFeature(deviceFeatureKey, deviceReducer),
    environmentModules,
    StoreModule.forFeature(SYSTEM_OBJECT.REPORTS, reportsReducer),
    StoreModule.forFeature(
      SYSTEM_OBJECT.SCHEDULED_REPORTS,
      scheduledReportsReducer
    ),
    StoreModule.forFeature(SYSTEM_OBJECT.DASHBOARDS, dashboardsReducer),
    StoreModule.forFeature(SYSTEM_OBJECT.WIDGETS, widgetReducer),
    StoreModule.forFeature(SYSTEM_OBJECT.ASSET_ENTITY, assetEntityReducer),
    StoreModule.forFeature(
      SYSTEM_OBJECT.PLATFORM_HEALTH_DATA,
      platformHealthReducer
    ),
    StoreModule.forFeature(SYSTEM_OBJECT.CUSTOM_COUNTERS, countersReducer),
    StoreModule.forFeature(SYSTEM_OBJECT.USERS, usersReducer),
    StoreModule.forFeature(SYSTEM_OBJECT.RULES, rulesReducer),
    StoreModule.forFeature(SYSTEM_OBJECT.GEOFENCES, geofenceReducer),
    StoreModule.forFeature(SYSTEM_OBJECT.ASSET_GROUPS, assetGroupReducer),
    StoreModule.forFeature(SYSTEM_OBJECT.SITES, sitesReducer),
    StoreModule.forFeature(SYSTEM_OBJECT.SECURITY_GROUP, securityGroupReducer),
    StoreModule.forFeature(SYSTEM_OBJECT.DEVICES, inventoryDeviceReducer),
    StoreModule.forFeature(SYSTEM_OBJECT.ASSETS, inventoryAssetReducer),
    StoreModule.forFeature(SYSTEM_OBJECT.PEOPLE, peopleReducer),
    StoreModule.forFeature(SYSTEM_OBJECT.WORKING_HOURS, workingHoursReducer),
    StoreModule.forFeature(
      SYSTEM_OBJECT.ASSET_SHARING_PROFILES,
      assetSharingProfilesReducer
    ),
    StoreModule.forFeature(
      SYSTEM_OBJECT.ASSETS_MASTER_DATA,
      assetsMasterDataReducer
    ),
    StoreModule.forFeature(SYSTEM_OBJECT.SERVICING_DATA, servicingDataReducer),
    StoreModule.forFeature(
      SYSTEM_OBJECT.HIERARCHIES_DATA,
      hierarchiesDataReducer
    ),
    StoreModule.forFeature(SYSTEM_OBJECT.INVENTORY, inventoryReducer),
    StoreModule.forFeature(SYSTEM_OBJECT.SYSTEM_EVENTS, systemEventsReducer),
  ],
})
export class AppStoreModule {}
