import {
  DELETE_SCHEDULED_REPORTS,
  STORE_SCHEDULED_REPORTS,
  UPDATE_SCHEDULED_REPORTS,
} from '../actions/action-types';

const initialState: any = {
  data: null,
};

export function scheduledReportsReducer(state = initialState, action): any {
  switch (action.type) {
    case STORE_SCHEDULED_REPORTS: {
      return { data: action.payload };
    }
    case DELETE_SCHEDULED_REPORTS: {
      return {
        data: state.data.filter((g) => g.id !== action.payload),
      };
    }
    case UPDATE_SCHEDULED_REPORTS: {
      const index = state.data.findIndex((p) => p.id === action.payload.id);
      state.data[index] = action.payload.data;
      return {
        data: state.data,
      };
    }
    default: {
      return state;
    }
  }
}
