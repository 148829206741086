import { createAction, props } from '@ngrx/store';
import { LOAD_RULES, LOAD_RULES_ERROR, STORE_RULES } from './action-types';

export const loadRulesData = createAction(LOAD_RULES);
export const loadRulesSuccess = createAction(
  STORE_RULES,
  props<{ payload: any[] }>()
);
export const loadRulesFailure = createAction(
  LOAD_RULES_ERROR,
  props<{ error: any }>()
);
