import { createAction, props } from '@ngrx/store';
import {
  GET_SITES_ERROR,
  GET_SITES_LOADING,
  GET_SITES_SUCCESS,
} from './action-types';

export const loadSitesData = createAction(GET_SITES_LOADING);
export const loadSitesSuccess = createAction(
  GET_SITES_SUCCESS,
  props<{ payload: any[] }>()
);
export const loadSitesFailure = createAction(
  GET_SITES_ERROR,
  props<{ error: any }>()
);
