import {
  INVENTORY_LOADING,
  INVENTORY_ERROR,
  INVENTORY_SUCCESS,
} from '../actions/action-types';

const initialState = {
  error: false,
  loading: false,
  data: null,
};

export function inventoryReducer(state = initialState, action): any {
  switch (action.type) {
    case INVENTORY_ERROR:
      return {
        error: true,
        loading: false,
        data: null,
      };
    case INVENTORY_SUCCESS:
      return {
        error: false,
        loading: false,
        data: action?.payload || null,
      };

    case INVENTORY_LOADING:
      return {
        error: false,
        loading: true,
        data: null,
      };

    default: {
      return state;
    }
  }
}
