import { Routes } from '@angular/router';

export const ROUTES_CLIENT_APPS: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./client-apps.component').then((m) => m.ClientAppsComponent),
    children: [
      {
        path: 'list',
        loadComponent: () =>
          import('./client-app-list/client-app-list.component').then(
            (m) => m.ClientAppListComponent
          ),
      },
      {
        path: ':id',
        loadComponent: () =>
          import('./client-app-details/client-app-details.component').then(
            (m) => m.ClientAppDetailsComponent
          ),
      },
      {
        path: 'user/:id',
        loadComponent: () =>
          import('./client-app-edit-user/client-app-edit-user.component').then(
            (m) => m.ClientAppEditUserComponent
          ),
      },
    ],
    pathMatch: 'prefix',
  },
];
