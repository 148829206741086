import { Injectable } from '@angular/core';
import { AppHttpService } from '@app/shared/services/app-http.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../appState';
import { SELECT_INVENTORY } from '../selectors/selectors';
import {
  loadInventory,
  loadInventorySuccess,
  loadInventoryFailure,
} from '../actions/inventory.action';

@Injectable()
export class InventoryEffects {
  constructor(
    public actions$: Actions,
    private appHttpService: AppHttpService,
    private store: Store<AppState>
  ) {}

  loadInventort$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadInventory),
      withLatestFrom(this.store.select(SELECT_INVENTORY)),
      filter(([_action, inventoryData]) => inventoryData?.data === null),
      switchMap(([_action, _inventoryData]) =>
        this.appHttpService.getInventoryData().pipe(
          map((data) => loadInventorySuccess({ payload: data })),
          catchError((error) => of(loadInventoryFailure({ error })))
        )
      )
    )
  );
}
