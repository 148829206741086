import {
  STORE_RULES,
  STORE_RULES_ERROR,
  DELETE_RULE,
  ADD_RULE,
  EDIT_RULE,
} from '../actions/action-types';

const initialState: RulesData = {
  rules: null,
  hasError: null,
};

interface RulesData {
  rules: any[];
  hasError: boolean;
}

export function rulesReducer(state = initialState, action): RulesData {
  switch (action.type) {
    case STORE_RULES:
      return {
        hasError: false,
        rules: action.payload,
      };

    case STORE_RULES_ERROR:
      return {
        hasError: true,
        rules: [],
      };

    case ADD_RULE:
      return {
        hasError: false,
        rules: [...state.rules, ...action.payload],
      };

    case EDIT_RULE:
      const index = state.rules.findIndex((r) => r.id === action.payload[0].id);
      state.rules[index] = { ...state.rules[index], ...action.payload[0] };
      return { ...state };

    case DELETE_RULE:
      const newRulesist = [
        ...state.rules.filter((g) => g.id !== action.payload),
      ];
      return {
        hasError: false,
        rules: newRulesist,
      };

    default: {
      return state;
    }
  }
}
