import { createAction, props } from '@ngrx/store';
import {
  WORKING_HOURS_ERROR,
  WORKING_HOURS_LOADING,
  STORE_WORKING_HOURS,
} from './action-types';

export const loadWorkingHoursData = createAction(WORKING_HOURS_LOADING);
export const loadWorkingHoursSuccess = createAction(
  STORE_WORKING_HOURS,
  props<{ payload: any[] }>()
);
export const loadWorkingHoursFailure = createAction(
  WORKING_HOURS_ERROR,
  props<{ error: any }>()
);
