import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ProgressSpinnerComponent } from '../progress-spinner/progress-spinner.component';

@Component({
    selector: 'app-loading',
    templateUrl: './app-loading.component.html',
    styleUrls: ['./app-loading.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ProgressSpinnerComponent],
})
export class AppLoadingComponent {}
