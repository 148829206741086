import { DeviceInfo } from '@asset-common/models';
import {
  STORE_DEVICE_INFO,
  STORE_DEVICE_DATA,
  STORE_DEVICE_HISTORY_DATA,
  STORE_DEVICE_EVENT_DATA,
  STORE_ASSET_DATA_CLEAR,
  STORE_EVENT_DATA_CLEAR,
} from '../actions/action-types';

const initialState: DeviceData = {
  deviceInfo: null,
  liveData: null,
  historyData: null,
  eventsData: null,
};

interface DeviceData {
  deviceInfo: DeviceInfo;
  liveData: any;
  historyData: any;
  eventsData: any;
}

export const deviceFeatureKey = 'DeviceData';

export function deviceReducer(state = initialState, action): DeviceData {
  switch (action.type) {
    case STORE_DEVICE_INFO:
      return {
        ...state,
        deviceInfo: action.payload,
      };
    case STORE_DEVICE_DATA:
      return {
        ...state,
        liveData: action.payload,
      };

    case STORE_DEVICE_HISTORY_DATA:
      return {
        ...state,
        historyData: action.payload,
      };

    case STORE_DEVICE_EVENT_DATA:
      return {
        ...state,
        eventsData: action.payload,
      };

    case STORE_ASSET_DATA_CLEAR:
      return {
        ...state,
        deviceInfo: null,
        liveData: null,
        historyData: null,
        eventsData: null,
      };

    case STORE_EVENT_DATA_CLEAR:
      return {
        ...state,
        eventsData: null,
      };

    default: {
      return state;
    }
  }
}
