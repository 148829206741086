<div class="cont1">
  <div class="i2">
    <div class="i3">
      <div class="message-wrap">
        <div class="message1">Session Expired</div>
        <div class="submessage1 text-gray-500 mb-4">
          Click reload to start a new session
        </div>
        <div class="text-gray-500 text-sm">Expired: {{ expiredDate }}</div>
        <div class="text-gray-500 text-sm mt-1">
          Last accessed page:
          <a [href]="getRoute()" class="link">{{ getRoute() }}</a>
        </div>
        <div class="mt-10">
          <p-button label="Reload" (click)="reload()"></p-button>
          <p-button
            label="Contact support"
            (click)="contactSupport()"
          ></p-button>
        </div>
      </div>
    </div>
  </div>
  <div class="watermark-container">
    <img src="/assets/logos/perspio-mono-black.svg" alt="Coming soon" />
  </div>
</div>
