import {
  GET_DASHBOARDS_ERROR,
  GET_DASHBOARDS_LOADING,
  STORE_DASHBOARDS,
  DELETE_DASHBOARD,
  ADD_DASHBOARD,
  SET_DASHBOARDS,
  CLEAR_EDITING_DASHBOARDS,
} from '../actions/action-types';

const initialState: Dashboards = {
  data: null,
  dashboardsLoading: false,
  dashboardsHasError: false,
  isEditMode: false,
  selectedWidget: null,
  dashboard: null,
  originalDashboard: null,
  dirty: false,
};

export interface Dashboards {
  data: any;
  dashboardsLoading: boolean;
  dashboardsHasError: boolean;
  isEditMode?: boolean;
  selectedWidget?: string;
  dashboard?: any;
  originalDashboard?: any;
  dirty?: boolean;
}

export function dashboardsReducer(state = initialState, action): Dashboards {
  switch (action.type) {
    case GET_DASHBOARDS_LOADING:
      return {
        ...state,
        data: null,
        dashboardsLoading: true,
        dashboardsHasError: false,
      };

    case STORE_DASHBOARDS:
      return {
        ...state,
        data: action.payload,
        dashboardsLoading: false,
        dashboardsHasError: false,
      };

    case SET_DASHBOARDS:
      return {
        ...state,
        ...action.payload,
      };

    case GET_DASHBOARDS_ERROR:
      return {
        ...state,
        data: null,
        dashboardsLoading: false,
        dashboardsHasError: true,
      };

    case DELETE_DASHBOARD:
      return {
        data: state.data.filter((g) => g.id !== action.payload),
        dashboardsLoading: false,
        dashboardsHasError: false,
      };

    case CLEAR_EDITING_DASHBOARDS:
      return {
        ...state,
        dashboardsLoading: false,
        dashboardsHasError: false,
        isEditMode: false,
        selectedWidget: null,
        dashboard: null,
        originalDashboard: null,
        dirty: false,
      };

    case ADD_DASHBOARD:
      return {
        data: [...state.data, ...action.payload],
        dashboardsLoading: false,
        dashboardsHasError: false,
      };

    default: {
      return state;
    }
  }
}
