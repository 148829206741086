import {
  STORE_DATE_RANGE,
  STORE_DATE_RANGE_CLEAR,
} from '../actions/action-types';

const initialState: DateRange = {
  dateRange: null,
  type: null,
  id: null,
};

interface DateRange {
  dateRange: {};
  type: string;
  id: string;
}

export function dateRangeReducer(state = initialState, action): DateRange {
  switch (action.type) {
    case STORE_DATE_RANGE:
      return {
        ...state,
        dateRange: action.payload.dateRange,
        type: action.payload.type,
        id: action.payload.id,
      };

    case STORE_DATE_RANGE_CLEAR:
      return {
        ...state,
        dateRange: null,
        type: null,
        id: null,
      };

    default: {
      return state;
    }
  }
}
