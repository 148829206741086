import {
  STORE_ROLE_DEFINITION,
  ROLE_DEFINITION_ERROR,
  ROLE_DEFINITION_LOADING,
} from '../actions/action-types';

const initialState: RoleDefinition = {
  isLoading: false,
  hasError: false,
  data: null,
};

interface RoleDefinition {
  isLoading: boolean;
  hasError: boolean;
  data: any;
}

export const assetFeatureKey = 'AssetData';

export function roleDefinitionReducer(state = initialState, action): any {
  switch (action.type) {
    case ROLE_DEFINITION_LOADING:
      return {
        isLoading: true,
        hasError: false,
        data: null,
      };

    case STORE_ROLE_DEFINITION:
      return {
        isLoading: false,
        hasError: false,
        data: action.payload,
      };

    case ROLE_DEFINITION_ERROR:
      return {
        isLoading: false,
        hasError: true,
        data: null,
      };

    default: {
      return state;
    }
  }
}
