import { STORE_USER_DATA } from '../actions/action-types';

const initialState = null;

export function userDataReducer(state = initialState, action): any {
  switch (action.type) {
    case STORE_USER_DATA:
      const data = action.payload;
      return { ...data };
    default: {
      return state;
    }
  }
}
