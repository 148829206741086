import { createAction, props } from '@ngrx/store';
import {
  GET_PEOPLE_ERROR,
  GET_PEOPLE_LOADING,
  GET_PEOPLE_SUCCESS,
} from './action-types';

export const loadingPeopleData = createAction(GET_PEOPLE_LOADING);
export const loadPeopleSuccess = createAction(
  GET_PEOPLE_SUCCESS,
  props<{ payload: any[] }>()
);
export const loadPeopleFailure = createAction(
  GET_PEOPLE_ERROR,
  props<{ error: any }>()
);
