import { createAction, props } from '@ngrx/store';
import {
  INVENTORY_LOADING,
  INVENTORY_SUCCESS,
  INVENTORY_ERROR,
} from './action-types';

export const loadInventory = createAction(INVENTORY_LOADING);
export const loadInventorySuccess = createAction(
  INVENTORY_SUCCESS,
  props<{ payload: any[] }>()
);
export const loadInventoryFailure = createAction(
  INVENTORY_ERROR,
  props<{ error: any }>()
);
