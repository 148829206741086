import {
  STORE_DOCKET_DATA,
  STORE_DOCKET_DATA_CLEAR,
} from '../actions/action-types';

const initialState: DocketData = {
  docketData: null,
};

interface DocketData {
  docketData: any;
}

export function docketReducer(state = initialState, action): DocketData {
  switch (action.type) {
    case STORE_DOCKET_DATA:
      return {
        ...state,
        docketData: action.payload,
      };

    case STORE_DOCKET_DATA_CLEAR:
      return {
        ...state,
        docketData: null,
      };

    default: {
      return state;
    }
  }
}
