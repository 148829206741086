import {
  STORE_TRIP_DATA,
  STORE_TRIP_DATA_CLEAR,
} from '../actions/action-types';

const initialState: TripData = {
  tripData: null,
};

interface TripData {
  tripData: {};
}

export function tripReducer(state = initialState, action): TripData {
  switch (action.type) {
    case STORE_TRIP_DATA:
      return {
        ...state,
        tripData: action.payload,
      };

    case STORE_TRIP_DATA_CLEAR:
      return {
        ...state,
        tripData: null,
      };

    default: {
      return state;
    }
  }
}
