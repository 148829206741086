import { Routes } from '@angular/router';

export const ROUTES_INVENTORY: Routes = [
  { path: '', redirectTo: 'assets', pathMatch: 'prefix' },
  {
    path: 'assets',
    loadComponent: () =>
      import('./components/assets/assets.component').then(
        (m) => m.AssetsComponent
      ),
  },
  {
    path: 'devices',
    loadComponent: () =>
      import('./components/devices/devices.component').then(
        (m) => m.DevicesComponent
      ),
  },
  {
    path: 'people-list',
    loadComponent: () =>
      import('./components/people/people.component').then(
        (m) => m.PeopleComponent
      ),
  },
];
