import { createAction, props } from '@ngrx/store';
import {
  GET_ASSET_GROUPS_ERROR,
  GET_ASSET_GROUPS_LOADING,
  STORE_ASSET_GROUPS,
} from './action-types';

export const loadAssetGroupData = createAction(GET_ASSET_GROUPS_LOADING);

export const loadAssetGroupDataSuccess = createAction(
  STORE_ASSET_GROUPS,
  props<{ payload: any[] }>()
);

export const loadAssetGroupDataFailure = createAction(
  GET_ASSET_GROUPS_ERROR,
  props<{ error: any }>()
);
