import { Injectable } from '@angular/core';
import { AppHttpService } from '@app/shared/services/app-http.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    catchError,
    filter,
    map,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../appState';
import { SELECT_RULE } from '../selectors/selectors';
import {
    loadRulesData,
    loadRulesFailure,
    loadRulesSuccess,
} from '../actions/rules.action';

@Injectable()
export class RulesEffects {
  constructor(
    public actions$: Actions,
    private appHttpService: AppHttpService,
    private store: Store<AppState>
  ) {}

  loadRuleData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRulesData),
      withLatestFrom(this.store.select(SELECT_RULE)),
      filter(([_action, ruleData]) => ruleData?.rules === null),
      switchMap(([_action, _ruleData]) =>
        this.appHttpService.getRules().pipe(
          map((data) => loadRulesSuccess({ payload: data })),
          catchError((error) => of(loadRulesFailure({ error })))
        )
      )
    )
  );
}
