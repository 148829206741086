import { STORE_ASSETS_MASTER_SCHEMA } from '../actions/action-types';

const initialState = null;

export function assetsMasterSchemaReducer(state = initialState, action): any {
  const data = action.payload;
  switch (action.type) {
    case STORE_ASSETS_MASTER_SCHEMA:
      return [...data];
    default: {
      return state;
    }
  }
}
