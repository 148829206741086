import { createAction, props } from '@ngrx/store';
import {
  GET_HIERARCHIES_DATA_ERROR,
  GET_HIERARCHIES_DATA_LOADING,
  STORE_HIERARCHIES_DATA,
} from './action-types';

export const loadHierarchiesData = createAction(GET_HIERARCHIES_DATA_LOADING);

export const loadHierarchiesDataSuccess = createAction(
  STORE_HIERARCHIES_DATA,
  props<{ payload: any[] }>()
);

export const loadHierarchiesDataFailure = createAction(
  GET_HIERARCHIES_DATA_ERROR,
  props<{ error: any }>()
);
