<div class="main-side-menu h-full w-14" [ngClass]="{ expand: showExpanded }">
  <div class="menu-toggle flex" (click)="MenuToggle()">
    <div
      class="logo-cont w-full"
      [ngClass]="{
        defaultLogo: showExpanded ? !largeLogo && !smallLogo : !smallLogo
      }"
      [ngStyle]="showExpanded && { width: '200px' }"
      >
      @if (showExpanded ? !largeLogo : !smallLogo) {
        <div class="logo flex">
          <div class="nav-logo-container">
            <img
              class="nav-logo h-24"
              alt="Inauro logo"
              src="../../../../../assets/logos/perspio-logo-white.svg"
              />
            </div>
          </div>
        }

        @if (showExpanded ? !!largeLogo : !!smallLogo) {
          <app-logo-image
            class="h-full"
            [path]="showExpanded ? largeLogo : smallLogo"
            [objectFit]="'cover'"
          ></app-logo-image>
        }
      </div>
    </div>
    <ul class="menu-list primary">
      <!-- primary menu -->
      @for (menu of primaryMenu; track idTrack(i, menu); let i = $index) {
        <li
      [ngClass]="{
        'active-item': menu.expanded,
        'has-submenu': menu.subMenu,
        disabled: menu?.disabled
      }"
          class="primary-menu"
          >
          <div (click)="onPrimaryMenuClick(menu, i)">
            <!-- slide out menu on hover -->
            @if (menu.subMenu) {
              <div
                class="extended-menu"
                [ngClass]="{ expand: showExpanded }"
                >
                <div class="extended-menu-list">
                  <div class="extended-menu-list-label">
                    {{ menu.label }}
                  </div>
                  <ul>
                    @for (
                      subMenu of menu.subMenu; track idTrack(j,
                      subMenu); let j = $index) {
                      <li
                        [ngClass]="{ 'active-item': subMenu.expanded }"
                        >
                        <a
                          [routerLink]="subMenu.route"
                          routerLinkActive="active"
                          #srla="routerLinkActive"
                          [ngClass]="{ disabled: subMenu.enabled }"
                          >
                          <div
                            class="w-12 h-12 relative flex flex-shrink-0 items-center"
                            >
                            <div class="highlight-block secondary"></div>
                            <app-icon
                              class="mx-auto text-base 2xl:text-lg"
                              [icon]="subMenu.icon"
                      [fontStyle]="
                        srla.isActive
                          ? iconConfig.highlighted
                          : iconConfig.default
                      "
                              [index]="j"
                              >
                            </app-icon>
                          </div>
                          <span class="extended-menu-title capitalize">{{
                            subMenu.label
                          }}</span>
                        </a>
                      </li>
                    }
                  </ul>
                </div>
              </div>
            }
            <a
              [routerLink]="menu.route"
              class="main"
              [ngClass]="{ primary: menu.subMenu, expanded: menu.expanded }"
              routerLinkActive="active"
              #rla="routerLinkActive"
              >
              <div
                class="h-12 relative flex flex-shrink-0 items-center"
                [ngClass]="{ 'w-12': showExpanded, 'w-full': !showExpanded }"
                [tooltipDisabled]="showExpanded || menu.subMenu"
                [pTooltip]="menu.label"
                tooltipPosition="right"
                >
                <div class="highlight-block"></div>
                <app-icon
                  class="mx-auto text-base flex text-xl"
                  [icon]="menu.icon"
              [fontStyle]="
                rla.isActive ? iconConfig.highlighted : iconConfig.default
              "
                  [index]="i"
                  >
                </app-icon>
              </div>
              <span class="capitalize">{{ menu.label }}</span>
            </a>
            <!-- menu with sub items -->
            <div
              class="extended-menu-container"
              [ngClass]="{ hidden: !menu.expanded }"
              >
              @for (
                subMenu of menu.subMenu; track idTrack(i,
                subMenu); let i = $index) {
                <a
                  [routerLink]="subMenu.route"
                  routerLinkActive="active"
                  #erla="routerLinkActive"
                  [ngClass]="{ disabled: subMenu.disabled }"
                  >
                  <div
                    class="w-12 h-12 relative flex flex-shrink-0 items-center"
                    [tooltipDisabled]="showExpanded"
                    [pTooltip]="subMenu.label"
                    tooltipPosition="right"
                    >
                    <div class="highlight-block secondary"></div>
                    <app-icon
                      class="mx-auto text-base 2xl:text-lg"
                      [icon]="subMenu.icon"
                [fontStyle]="
                  erla.isActive ? iconConfig.highlighted : iconConfig.default
                "
                      [index]="i"
                      >
                    </app-icon>
                  </div>
                  <span class="capitalize">{{ subMenu.label }}</span>
                </a>
              }
            </div>
          </div>
        </li>
      }
    </ul>
    <div
      class="flex flex-col justify-center bottom-sec"
      [ngStyle]="showExpanded && { width: '179px' }"
      >
      <ul class="menu-list">
        <li class="flex justify-center relative">
          <div
            class="h-16 flex flex-shrink-0 items-center mid-divider absolute"
            [ngStyle]="showExpanded && { width: '85%' }"
          ></div>
        </li>
        <!-- additional menu -->
        @for (menu of additionalMenu; track idTrack(p, menu); let p = $index) {
          <li
            class="mt-2"
            (click)="onPrimaryMenuClick(menu)"
            >
            <a
              [routerLink]="menu.route"
              routerLinkActive="active"
              #acerla="routerLinkActive"
              [ngClass]="{ disabled: menu.disabled }"
              >
              <div
                class="w-12 h-12 relative flex flex-shrink-0 items-center"
                [tooltipDisabled]="showExpanded"
                [pTooltip]="menu.label"
                tooltipPosition="right"
                >
                <div class="highlight-block"></div>
                <app-icon
                  class="mx-auto text-base text-xl"
                  [icon]="menu.icon"
              [fontStyle]="
                acerla.isActive ? iconConfig.selected : iconConfig.default
              "
                  [index]="i"
                  >
                </app-icon>
              </div>
              <span class="capitalize">{{ menu.label }}</span>
            </a>
          </li>
        }
      </ul>
      <div
        class="w-12 h-12 relative flex flex-shrink-0 items-center double-chev"
        [ngClass]="{ 'exp-chev': showExpanded }"
        (click)="MenuToggle()"
        [pTooltip]="showExpanded ? 'Collapse menu' : 'Expand menu'"
        tooltipPosition="right"
        >
        <div [ngClass]="{ 'w-full': !showExpanded }">
          <app-icon
            class="text-base text-lg"
          [icon]="
            showExpanded ? 'fa-chevron-double-left' : 'fa-chevron-double-right'
          "
            [fontStyle]="'fa-light'"
            [ngClass]="{ block: !showExpanded, 'mx-auto': !showExpanded }"
            >
          </app-icon>
        </div>

        @if (showExpanded) {
          <span class="self-center collapse-text"
            >Collapse</span
            >
        }
      </div>

      @if (!showExpanded && version) {
        <div
          class="flex flex-col items-center text-xs version-text mt-1"
          >
          @if (!showExpanded) {
            <div>{{ 'v ' + version[0] + version[1] }}</div>
          }
          <div>&#169; 2024</div>
        </div>
      }
      @if (showExpanded && version) {
        <div
          class="flex flex-col items-center text-xs version-text mt-1"
          >
          @if (showExpanded) {
            <div>{{ 'v ' + version }}</div>
          }
          <div>&#169; 2024 PERSPIO BY INAURO</div>
        </div>
      }
    </div>
  </div>
