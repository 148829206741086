import { inject } from '@angular/core';
import { Routes, ActivatedRouteSnapshot } from '@angular/router';
import { RouteAuthGuard } from '@app/shared/services/route-guard.service';
import { ObjectIdResolver } from '@app/shared/resolver/object-id.resolver';

export const ROUTES_ASSET_TRACKER: Routes = [
  { path: '', redirectTo: 'map', pathMatch: 'full' },
  {
    path: 'map',
    loadComponent: () =>
      import('./components/asset-map-view/asset-map-view.component').then(
        (m) => m.AssetMapViewComponent
      ),
  },
  {
    path: 'list',
    loadComponent: () =>
      import('./components/asset-list-view/asset-list-view.component').then(
        (m) => m.AssetListViewComponent
      ),
  },
  {
    path: 'stats',
    loadComponent: () =>
      import('@asset-common/components/stats/stats.component').then(
        (m) => m.StatsComponent
      ),
  },
  {
    path: 'add',
    loadComponent: () =>
      import('./pages/add-asset/add-asset.component').then(
        (m) => m.AddAssetComponent
      ),
  },
  {
    path: ':objectId',
    loadComponent: () =>
      import('./pages/asset-detail/asset-detail.component').then(
        (m) => m.AssetDetailComponent
      ),
    children: [
      { path: '', redirectTo: 'snapshot', pathMatch: 'prefix' },
      {
        path: 'snapshot',
        loadComponent: () =>
          import(
            './pages/asset-detail/detail-panels/detail-live/detail-live.component'
          ).then((m) => m.DetailLiveComponent),
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
        ],
        data: { module: 'asset-tracker', subModule: 'live' },
      },
      {
        path: 'activity',
        loadComponent: () =>
          import(
            './pages/asset-detail/detail-panels/detail-activity/detail-activity.component'
          ).then((m) => m.DetailActivityComponent),

        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
        ],
        data: { module: 'asset-tracker', subModule: 'activity' },
      },
      {
        path: 'utilisation',
        loadComponent: () =>
          import(
            './pages/asset-detail/detail-panels/detail-utilisation/detail-utilisation.component'
          ).then((m) => m.DetailUtilisationComponent),
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
        ],
        data: { module: 'asset-tracker', subModule: 'utilisation' },
      },
      {
        path: 'events',
        loadComponent: () =>
          import(
            './pages/asset-detail/detail-panels/detail-alarms/detail-alarms.component'
          ).then((m) => m.DetailAlarmsComponent),
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
        ],
        data: { module: 'asset-tracker', subModule: 'events' },
      },
      {
        path: 'asset-details',
        loadComponent: () =>
          import(
            './pages/asset-detail/detail-panels/detail-asset-particulars/detail-asset-particulars.component'
          ).then((m) => m.DetailAssetParticularsComponent),

        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
        ],
        data: { module: 'asset-tracker', subModule: 'asset-details' },
      },
      {
        path: 'metrics',
        loadComponent: () =>
          import(
            './pages/asset-detail/detail-panels/detail-metrics/metrics-wrapper/metrics-wrapper.component'
          ).then((m) => m.MetricsWrapperComponent),
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
        ],
        data: { module: 'asset-tracker', subModule: 'metrics' },
      },

      {
        path: 'configuration',
        loadComponent: () =>
          import(
            './pages/asset-detail/detail-panels/detail-configuration/detail-configuration.component'
          ).then((m) => m.DetailConfigurationComponent),
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
        ],
        data: { module: 'asset-tracker', subModule: 'configuration' },
      },
      {
        path: 'servicing',
        loadComponent: () =>
          import(
            './pages/asset-detail/detail-panels/detail-servicing/detail-servicing.component'
          ).then((m) => m.DetailServicingComponent),
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
        ],
        data: { module: 'asset-tracker', subModule: 'servicing' },
      },
      {
        path: 'history-replay',
        loadComponent: () =>
          import(
            './pages/asset-detail/detail-panels/detail-history-replay/detail-history-replay.component'
          ).then((m) => m.DetailHistoryReplayComponent),
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
        ],
        data: { module: 'asset-tracker', subModule: 'history-replay' },
      },
      {
        path: 'erp-history',
        loadComponent: () =>
          import(
            './pages/asset-detail/detail-panels/detail-erp-history/detail-erp-history.component'
          ).then((m) => m.ErpHistoryComponent),
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
        ],
        data: { module: 'asset-tracker', subModule: 'erp-history' },
      },
      {
        path: 'audit-log',
        loadComponent: () =>
          import('../../shared/components/audit-log/audit-log.component').then(
            (m) => m.AuditLogComponent
          ),
        resolve: { objectId: ObjectIdResolver },
        data: { module: 'asset-tracker', subModule: 'audit-log' },
      },
    ],
  },
];
