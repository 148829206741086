import {
  STORE_DEVICE_INVENTORY,
  STORE_DEVICE_INVENTORY_ERROR,
} from '../actions/action-types';

const initialState: InventoryDeviceData = {
  deviceList: null,
  hasError: null,
};

interface InventoryDeviceData {
  deviceList: any[];
  hasError: boolean;
}

export function inventoryDeviceReducer(
  state = initialState,
  action
): InventoryDeviceData {
  switch (action.type) {
    case STORE_DEVICE_INVENTORY:
      return {
        hasError: false,
        deviceList: action.payload,
      };

    case STORE_DEVICE_INVENTORY_ERROR:
      return {
        hasError: true,
        deviceList: null,
      };

    default: {
      return state;
    }
  }
}
