import {
  STORE_MAP_OPTIONS_CLUSTER,
  STORE_MAP_OPTIONS_CLUSTER_RENDERER,
  STORE_MAP_OPTIONS_GEOFENCE,
  STORE_MAP_OPTIONS_TOLL,
  STORE_MAP_OPTIONS_TRAFFIC,
  STORE_MAP_OPTIONS_ROUTE,
  STORE_MAP_OPTIONS_MODE,
  STORE_MAP_OPTIONS_DATASETS,
  STORE_MAP_OPTIONS_HEATMAP,
} from '../actions/action-types';

const initialState: MapOptions = {
  cluster: true,
  clusterRenderer: true,
  geofence: false,
  toll: false,
  traffic: false,
  route: false,
  mapMode: 'map',
  datasets: false,
  heatmap: false,
};

interface MapOptions {
  cluster: any;
  clusterRenderer: any;
  geofence: any;
  toll: any;
  traffic: any;
  route: any;
  mapMode: any;
  datasets: any;
  heatmap: any;
}

export function mapReducer(state = initialState, action): MapOptions {
  switch (action.type) {
    case STORE_MAP_OPTIONS_CLUSTER:
      return {
        ...state,
        cluster: action.payload,
      };
    case STORE_MAP_OPTIONS_CLUSTER_RENDERER:
      return {
        ...state,
        clusterRenderer: action.payload,
      };

    case STORE_MAP_OPTIONS_HEATMAP:
      return {
        ...state,
        heatmap: action.payload,
      };

    case STORE_MAP_OPTIONS_GEOFENCE:
      return {
        ...state,
        geofence: action.payload,
      };
    case STORE_MAP_OPTIONS_TOLL:
      return {
        ...state,
        toll: action.payload,
      };

    case STORE_MAP_OPTIONS_TRAFFIC:
      return {
        ...state,
        traffic: action.payload,
      };

    case STORE_MAP_OPTIONS_ROUTE:
      return {
        ...state,
        route: action.payload,
      };

    case STORE_MAP_OPTIONS_MODE:
      return {
        ...state,
        mapMode: action.payload,
      };

    case STORE_MAP_OPTIONS_DATASETS:
      return {
        ...state,
        datasets: action.payload,
      };

    default: {
      return state;
    }
  }
}
