import { createAction, props } from '@ngrx/store';
import {
  LOAD_DEVICE_INVENTORY,
  LOAD_DEVICE_INVENTORY_ERROR,
  STORE_DEVICE_INVENTORY,
} from './action-types';

export const loadDeviceData = createAction(LOAD_DEVICE_INVENTORY);
export const loadDeviceSuccess = createAction(
  STORE_DEVICE_INVENTORY,
  props<{
    payload: {
      tolls: any[];
      geofences: any[];
    };
  }>()
);
export const loadDeviceFailure = createAction(
  LOAD_DEVICE_INVENTORY_ERROR,
  props<{ error: any }>()
);
