<!--This is to avoid reload during acquireTokenSilent() because of hidden MSAL iframe -->
@if (!roleDefinitionLoading) {
  <div class="h-full flex">
    <app-overlay-spinner></app-overlay-spinner>
    @if (!showSessionIdleModal && primaryMenu?.length > 0) {
      <app-main-side-bar
        [showExpanded]="showExpanded"
        [additionalMenu]="additionalMenu"
        [MenuToggle]="onMenuToggle"
        [primaryMenu]="primaryMenu"
        [smallLogo]="smallLogo"
        [largeLogo]="largeLogo"
      ></app-main-side-bar>
    }
    <div class="flex flex-grow">
      @if (!isIframe && !showSessionIdleModal) {
        <router-outlet></router-outlet>
      }
    </div>
    @if (showSessionIdleModal) {
      <app-session-idle-dialog
      ></app-session-idle-dialog>
    }
  </div>
}
@if (roleDefinitionLoading) {
  <app-loading></app-loading>
}
