import { STORE_REPORTS, UPDATE_REPORT_TZ_VALUE } from '../actions/action-types';

const initialState: ReportState = {
  data: null,
};

export function reportsReducer(state = initialState, action): ReportState {
  if (action.type === STORE_REPORTS) {
    return { data: action.payload };
  }
  if (action.type === UPDATE_REPORT_TZ_VALUE) {
    const data = state?.data?.map((report) =>
      report.id === action.payload.reportId
        ? { ...report, timezone: action.payload.newValue }
        : report
    );
    return {
      ...state,
      data,
    };
  } else {
    return state;
  }
}
