import { createAction, props } from '@ngrx/store';
import {
  ASSET_SHARING_PROFILES_ERROR,
  ASSET_SHARING_PROFILES_LOADING,
  STORE_ASSET_SHARING_PROFILES,
} from './action-types';

export const loadAssetSharingData = createAction(
  ASSET_SHARING_PROFILES_LOADING
);
export const loadAssetSharingSuccess = createAction(
  STORE_ASSET_SHARING_PROFILES,
  props<{ payload: any[] }>()
);
export const loadAssetSharingFailure = createAction(
  ASSET_SHARING_PROFILES_ERROR,
  props<{ error: any }>()
);
