import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconConfig } from '@app/shared/configs/icons.config';
import { NgClass, NgStyle } from '@angular/common';
import { LogoImageComponent } from '../../../shared/components/logo-image/logo-image.component';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { IconComponent } from '../../../shared/components/icon/icon.component';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-main-side-bar',
  templateUrl: './main-side-bar.component.html',
  styleUrls: ['./main-side-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    NgStyle,
    LogoImageComponent,
    RouterLinkActive,
    RouterLink,
    IconComponent,
    TooltipModule,
  ],
})
export class MainSideBarComponent {
  version = '24.09.19';
  @Input() showExpanded;
  @Input() additionalMenu;
  @Input() MenuToggle;
  @Input() primaryMenu;
  @Input() largeLogo;
  @Input() smallLogo;
  iconConfig = IconConfig;

  onPrimaryMenuClick(menu: any, i): void {
    if (menu.disabled) {
      return;
    }
    this.primaryMenu.forEach((element: any, index) => {
      element.expanded = index && i === index;
    });
  }

  idTrack(_index, item): any {
    return item.id;
  }
}
