import { Injectable } from '@angular/core';
import { AppHttpService } from '@app/shared/services/app-http.service';
import { AppInitialiseService } from '@app/shared/services/app-initialize.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { withLatestFrom, switchMap, of, map, catchError, filter } from 'rxjs';
import {
  loadAssetInventoryData,
  loadAssetInventoryFailure,
  loadAssetInventorySuccess,
} from '../actions/assets.action';
import { AppState } from '../appState';

@Injectable()
export class AssetInventoryEffects {
  constructor(
    private actions$: Actions,
    private appHttpService: AppHttpService,
    private store: Store<AppState>,
    private appInitialiseService: AppInitialiseService
  ) {}

  dataSelector = (state: AppState) => state;

  AssetInventoryData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAssetInventoryData),
      withLatestFrom(this.store.select(this.dataSelector)),
      filter(([_action, data]) => data?.InventoryAssetData?.devices === null),
      switchMap(([_a, d]) => {
        return this.appHttpService
          .getAssets(d?.UserData?.extensions[0]?.additionalData?.assetFilters)
          .pipe(
            map((assets) => {
              this.appInitialiseService.loadInitialData(assets);
              return loadAssetInventorySuccess({ payload: assets });
            }),
            catchError((error) => of(loadAssetInventoryFailure({ error })))
          );
      })
    )
  );
}
