import { Routes } from '@angular/router';

export const ROUTES_PROXIMITY_SEARCH: Routes = [
  { path: '', redirectTo: 'cards', pathMatch: 'prefix' },
  {
    path: 'cards',
    loadComponent: () =>
      import(
        './pages/proximity-search-summary/proximity-search-summary.component'
      ).then((m) => m.ProximitySearchSummaryComponent),
  },
  {
    path: 'list',
    loadComponent: () =>
      import(
        './components/proximity-search-list-view/proximity-search-list-view.component'
      ).then((m) => m.ProximitySearchListViewComponent),
  },
  {
    path: ':objectId',

    loadComponent: () =>
      import(
        './pages/proximity-search-detail/proximity-search-detail.component'
      ).then((m) => m.ProximitySearchDetailComponent),
    children: [
      { pathMatch: 'prefix', path: '', redirectTo: 'snapshot' },
      {
        path: 'snapshot',
        loadComponent: () =>
          import(
            './components/proximity-search-snapshot/proximity-search-snapshot.component'
          ).then((m) => m.ProximitySearchSnapshotComponent),
      },
      {
        path: 'conditions-search',
        loadComponent: () =>
          import(
            './components/conditions-search/conditions-search.component'
          ).then((m) => m.ConditionsSearchComponent),
      },
    ],
  },

  {
    path: '',
    redirectTo: 'search/cards',
    pathMatch: 'full',
  },
];
