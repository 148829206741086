import { ActionReducerMap } from '@ngrx/store';
import { AppState } from '../appState';
import { deviceReducer } from './device.reducer';
import { assetReducer } from './asset.reducer';
import { labelsReducer } from './labels.reducer';
import { inventoryAssetReducer } from './inventory-asset.reducer';
import { mapReducer } from './map-options.reducer';
import { platformHealthReducer } from './platform-health.reducer';
import { roleDefinitionReducer } from './role-definition.reducer';
import { referenceReducer } from './reference.reducer';
import { sitesReducer } from './sites.reducer';
import { dateRangeReducer } from './dates.reducer';
import { tripReducer } from './trips.reducer';
import { assetRelationshipsReducer } from './asset-relationships.reducer';
import { assetHistoryReducer } from './asset-history.reducer';
import { metricsDataReducer } from './asset-metrics.reducer';
import { assetInfoReducer } from './asset-info.reducer';
import { assetGroupReducer } from './asset-group.reducer';
import { deviceSchemasReducer } from './device-schemas.reducer';
import { rulesReducer } from './rules.reducer';
import { tenantConfigurationReducer } from './tenant-configuration.reducer';
import { usersReducer } from './users.reducer';
import { userDataReducer } from './user-data.reducer';
import { securityGroupReducer } from './security-group.reducer';
import { dashboardsReducer } from './dashboards.reducer';
import { reportsReducer } from './reports.reducer';
import { countersReducer } from './counters.reducer';
import { docketReducer } from './docket.reducer';
import { peopleReducer } from './people.reducer';
import { workingHoursReducer } from './working-hours.reducer';
import { widgetReducer } from '../entities/widgets-entity/widgets-entity.reducer';
import { assetsMasterSchemaReducer } from './assets-master-schema.reducer';
import { assetsMasterDataReducer } from './assets-master-data.reducer';
import { servicingDataReducer } from './servicing-data.reducer';
import { assetSharingProfilesReducer } from './asset-sharing-profiles.reducer';
import { hierarchiesDataReducer } from './hierarchies-data.reducer';
import { inventoryReducer } from './inventory.reducer';
import { systemEventsReducer } from './system-events.reducer';
import { assetEntityReducer } from '../entities/assets-entity/assets-entity.reducer';

export const reducers: ActionReducerMap<AppState> = {
  AssetData: assetReducer,
  AssetGroups: assetGroupReducer,
  AssetHistory: assetHistoryReducer,
  AssetInfo: assetInfoReducer,
  AssetRelationships: assetRelationshipsReducer,
  AssetsMasterSchema: assetsMasterSchemaReducer,
  AssetsMasterData: assetsMasterDataReducer,
  Counters: countersReducer,
  Dashboards: dashboardsReducer,
  DateRange: dateRangeReducer,
  DeviceData: deviceReducer,
  DeviceSchemas: deviceSchemasReducer,
  HierarchiesData: hierarchiesDataReducer,
  InventoryAssetData: inventoryAssetReducer,
  LabelsData: labelsReducer,
  MapOptions: mapReducer,
  MetricsData: metricsDataReducer,
  PlatformHealthData: platformHealthReducer,
  ReferenceData: referenceReducer,
  Reports: reportsReducer,
  RoleDefinition: roleDefinitionReducer,
  RuleData: rulesReducer,
  SecurityGroups: securityGroupReducer,
  ServicingData: servicingDataReducer,
  Sites: sitesReducer,
  TenantConfiguration: tenantConfigurationReducer,
  TripData: tripReducer,
  UserData: userDataReducer,
  Users: usersReducer,
  DocketData: docketReducer,
  People: peopleReducer,
  WorkingHours: workingHoursReducer,
  AssetSharingProfiles: assetSharingProfilesReducer,
  Inventory: inventoryReducer,
  SystemEvents: systemEventsReducer,
  Widgets: widgetReducer,
  AssetEntity: assetEntityReducer,
};
