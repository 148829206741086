import { Routes } from '@angular/router';

export const ROUTES_TRANSPORT: Routes = [
  { path: '', redirectTo: 'map', pathMatch: 'prefix' },
  {
    path: 'map',
    loadComponent: () =>
      import(
        './components/transport-map-view/transport-map-view.component'
      ).then((m) => m.TransportMapViewComponent),
  },
  {
    path: 'list',
    loadComponent: () =>
      import(
        './components/transport-list-view/transport-list-view.component'
      ).then((m) => m.TransportListViewComponent),
  },
  {
    path: '',
    redirectTo: 'assets/map',
    pathMatch: 'full',
  },
];
