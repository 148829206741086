import { createAction, props } from '@ngrx/store';
import {
  COUNTERS_ERROR,
  COUNTERS_LOADING,
  STORE_COUNTERS,
} from './action-types';

export const loadCounterData = createAction(COUNTERS_LOADING);
export const loadCountersSuccess = createAction(
  STORE_COUNTERS,
  props<{ payload: any[] }>()
);
export const loadCountersFailure = createAction(
  COUNTERS_ERROR,
  props<{ error: any }>()
);
