import {
  COUNTERS_ERROR,
  COUNTERS_LOADING,
  STORE_COUNTERS,
  DELETE_COUNTER,
  EDIT_COUNTER,
} from '../actions/action-types';

const initialState: CountersDefinition = {
  isLoading: false,
  hasError: false,
  data: null,
};

interface CountersDefinition {
  isLoading: boolean;
  hasError: boolean;
  data: any;
}

export function countersReducer(state = initialState, action): any {
  switch (action.type) {
    case COUNTERS_LOADING:
      return {
        isLoading: true,
        hasError: false,
        data: null,
      };

    case STORE_COUNTERS:
      return {
        isLoading: false,
        hasError: false,
        data: action.payload,
      };

    case COUNTERS_ERROR:
      return {
        isLoading: false,
        hasError: true,
        data: null,
      };

    case DELETE_COUNTER:
      return {
        isLoading: false,
        hasError: false,
        data: state.data.filter((g) => g?.id !== action?.payload?.id),
      };

    case EDIT_COUNTER:
      const index = state.data.findIndex((p) => p.id === action.payload.id);
      state.data[index] = action.payload.data;
      return {
        isLoading: false,
        hasError: false,
        data: state.data,
      };

    default: {
      return state;
    }
  }
}
