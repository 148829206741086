import { CLEAR_ASSET_INFO, STORE_ASSET_INFO } from '../actions/action-types';

const initialState: AssetInfo = {
  assetInfo: null,
};

interface AssetInfo {
  assetInfo: any;
}

export const assetFeatureKey = 'AssetInfo';

export function assetInfoReducer(state = initialState, action): AssetInfo {
  switch (action.type) {
    case STORE_ASSET_INFO:
      return {
        ...state,
        assetInfo: action.payload,
      };
    case CLEAR_ASSET_INFO:
      return {
        assetInfo: null,
      };

    default: {
      return state;
    }
  }
}
