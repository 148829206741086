import {
  STORE_USERS,
  DELETE_USER,
  ADD_USER,
  ADD_USER_ACCESS,
  EDIT_USER,
  EDIT_USER_ACCESS,
} from '../actions/action-types';

const initialState = null;

export function usersReducer(state = initialState, action): any {
  switch (action.type) {
    case STORE_USERS:
      const users = action.payload?.map((user) => {
        return {
          ...user,
          permissions:
            user?.extensions[0]?.additionalData?.accessProfile || 'NA',
        };
      });
      return [...users];

    case DELETE_USER:
      return state.filter((g) => g.userPrincipalName !== action.payload);

    case ADD_USER:
      return [...state, ...action.payload];

    case ADD_USER_ACCESS:
      const index = state.findIndex(
        (user) => user.userPrincipalName === action.payload.userPrincipalName
      );
      state[index].newAccess = action.payload.accessProfile;
      return [...state];

    case EDIT_USER:
      return [
        ...state.map((s) => {
          if (s?.userPrincipalName === action?.payload?.userPrincipalName) {
            return action?.payload?.newObject;
          } else {
            return s;
          }
        }),
      ];

    case EDIT_USER_ACCESS:
      const index1 = state.findIndex(
        (user) => user.userPrincipalName === action.payload.userPrincipalName
      );
      state[index1].access = action.payload.access;
      return [...state];

    default: {
      return state;
    }
  }
}
