import {
  STORE_ASSET_INVENTORY,
  STORE_ASSET_INVENTORY_ERROR,
  STORE_ASSET_SHOW_DISABLED_FLAG,
  DELETE_ASSET,
  CLEAR_ASSET_INVENTORY,
  LOAD_ASSET_INVENTORY,
  ADD_ASSET,
} from '../actions/action-types';

const initialState: AssetInventoryState = {
  devices: null,
  hasError: null,
  showDisabled: false,
  isLoading: false,
};

export function inventoryAssetReducer(
  state = initialState,
  action
): AssetInventoryState {
  switch (action.type) {
    case STORE_ASSET_INVENTORY:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        devices: action.payload,
      };

    case STORE_ASSET_INVENTORY_ERROR:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        devices: null,
      };

    case STORE_ASSET_SHOW_DISABLED_FLAG:
      return {
        ...state,
        showDisabled: action.payload,
      };

    case DELETE_ASSET:
      const devicesList = state.devices.filter(
        (asset) => asset.assetId !== action.payload.id
      );
      return {
        ...state,
        hasError: false,
        isLoading: false,
        devices: devicesList,
      };

    case ADD_ASSET:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        devices: [...state.devices, ...[action.payload]],
      };

    case CLEAR_ASSET_INVENTORY:
      return {
        devices: null,
        hasError: false,
        showDisabled: false,
        isLoading: false,
      };

    case LOAD_ASSET_INVENTORY:
      return {
        devices: null,
        hasError: false,
        showDisabled: state.showDisabled,
        isLoading: true,
      };

    default: {
      return state;
    }
  }
}
