import {
  STORE_ASSET_RELATIONSHIPS,
  STORE_ASSET_RELATIONSHIPS_SELECTED_DEVICE,
  CLEAR_ASSET_RELATIONSHIPS_DATA,
  STORE_ASSET_RELATIONSHIPS_DATASOURCE,
} from '../actions/action-types';

const initialState: AssetRelationships = {
  relationships: null,
  snapshots: null,
  preferredDevice: null,
  selectedDevice: null,
  assetId: null,
  customerAssetId: null,
  assetTypeId: null,
  dataSource: null,
};

interface AssetRelationships {
  relationships: any;
  snapshots: any;
  preferredDevice: any;
  selectedDevice: any;
  assetId: any;
  customerAssetId: any;
  assetTypeId: any;
  dataSource: any;
}

export const assetFeatureKey = 'AssetRelationships';

export function assetRelationshipsReducer(
  state = initialState,
  action
): AssetRelationships {
  switch (action.type) {
    case STORE_ASSET_RELATIONSHIPS:
      return {
        ...state,
        relationships: action?.payload?.relationships,
        snapshots: action?.payload?.snapshots,
        preferredDevice: action?.payload?.preferredDevice,
        selectedDevice: action?.payload?.selectedDevice,
        assetId: action?.payload?.assetId,
        customerAssetId: action?.payload?.customerAssetId,
        assetTypeId: action?.payload?.assetTypeId,
        dataSource: action?.payload?.dataSource,
      };

    case STORE_ASSET_RELATIONSHIPS_SELECTED_DEVICE:
      return {
        ...state,
        selectedDevice: action?.payload?.selectedDevice,
      };

    case STORE_ASSET_RELATIONSHIPS_DATASOURCE:
      return {
        ...state,
        dataSource: action.payload,
      };

    case CLEAR_ASSET_RELATIONSHIPS_DATA:
      return {
        ...state,
        relationships: null,
        snapshots: null,
        preferredDevice: null,
        selectedDevice: null,
        assetId: null,
        customerAssetId: null,
        assetTypeId: null,
        dataSource: null,
      };

    default: {
      return state;
    }
  }
}
