import { createAction, props } from '@ngrx/store';
import {
  LOAD_USER_DATA,
  LOAD_USER_DATA_ERROR,
  STORE_USERS,
} from './action-types';

export const loadUserData = createAction(LOAD_USER_DATA);
export const loadUserSuccess = createAction(
  STORE_USERS,
  props<{ payload: any[] }>()
);
export const loadUserFailure = createAction(
  LOAD_USER_DATA_ERROR,
  props<{ error: any }>()
);
