import {
  ASSET_SHARING_PROFILES_LOADING,
  STORE_ASSET_SHARING_PROFILES,
  ASSET_SHARING_PROFILES_ERROR,
  DELETE_ASSET_SHARING_PROFILE,
  EDIT_ASSET_SHARING_PROFILE,
  ADD_ASSET_SHARING_PROFILE,
} from '../actions/action-types';

const initialState: AssetSharingProfilesDefinition = {
  isLoading: false,
  hasError: false,
  data: null,
};

interface AssetSharingProfilesDefinition {
  isLoading: boolean;
  hasError: boolean;
  data: any;
}

export function assetSharingProfilesReducer(state = initialState, action): any {
  switch (action.type) {
    case ASSET_SHARING_PROFILES_LOADING:
      return {
        isLoading: true,
        hasError: false,
        data: null,
      };

    case STORE_ASSET_SHARING_PROFILES:
      return {
        isLoading: false,
        hasError: false,
        data: action.payload,
      };

    case ASSET_SHARING_PROFILES_ERROR:
      return {
        isLoading: false,
        hasError: true,
        data: null,
      };

    case DELETE_ASSET_SHARING_PROFILE:
      return {
        isLoading: false,
        hasError: false,
        data: state.data.filter((g) => g?.id !== action?.payload?.id),
      };

    case EDIT_ASSET_SHARING_PROFILE:
      const index = state.data.findIndex((p) => p.id === action?.payload?.id);
      state.data[index] = action?.payload?.data;
      return {
        isLoading: false,
        hasError: false,
        data: state.data,
      };

    case ADD_ASSET_SHARING_PROFILE:
      const newData = [...state.data, ...action.payload];
      return {
        isLoading: false,
        hasError: false,
        data: newData,
      };

    default: {
      return state;
    }
  }
}
