import { createAction, props } from '@ngrx/store';
import {
  LOAD_PLATFORM_HEALTH_DATA_ACTION,
  LOAD_PLATFORM_HEALTH_DATA_ACTION_ERROR,
  STORE_PLATFORM_HEALTH_DATA_ACTION,
} from './action-types';

export const loadPlatformHealthData = createAction(
  LOAD_PLATFORM_HEALTH_DATA_ACTION
);

export const loadPlatformHealthDataSuccess = createAction(
  STORE_PLATFORM_HEALTH_DATA_ACTION,
  props<{ payload: any[] }>()
);

export const loadPlatformHealthFailure = createAction(
  LOAD_PLATFORM_HEALTH_DATA_ACTION_ERROR,
  props<{ error: any }>()
);
