<div>
  <div class="wrapper"></div>
  <div class="watermark-container">
    <svg
      version="1.1"
      id="Logo"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 30.1 33.5"
      style="enable-background: new 0 0 159.1 37.5"
      xml:space="preserve"
    >
      <g>
        <linearGradient
          id="SVGID_1_"
          gradientUnits="userSpaceOnUse"
          x1="13.5023"
          y1="31.0609"
          x2="20.9798"
          y2="3.1547"
        >
          <stop offset="0" style="stop-color: #4fc8ed" />
          <stop offset="0.6508" style="stop-color: #488cca" />
        </linearGradient>
        <path
          class="st0"
          d="M22.7,17.1v-5.8c0-0.3-0.2-0.6-0.4-0.7l-5-2.9C17.1,7.6,17,7.4,17,7.2V2.5c0-0.3-0.3-0.6-0.6-0.6h-2.2
        c-0.3,0-0.6,0.3-0.6,0.6v4.7c0,0.2-0.1,0.4-0.3,0.5l-5,2.9c-0.3,0.1-0.4,0.4-0.4,0.7v5.8c0,0.2-0.1,0.4-0.3,0.5l-5,2.9
        c-0.3,0.1-0.4,0.4-0.4,0.7v6.5c0,0.3,0.3,0.6,0.6,0.6H5c0.3,0,0.6-0.3,0.6-0.6v-4.7c0-0.2,0.1-0.4,0.3-0.5l3.5-2
        c0.2-0.1,0.4-0.1,0.5,0l3.5,2c0.2,0.1,0.3,0.3,0.3,0.5v4.7c0,0.3,0.3,0.6,0.6,0.6h2.2c0.3,0,0.6-0.3,0.6-0.6l0-4.7
        c0-0.2,0.1-0.4,0.3-0.5l3.5-2c0.2-0.1,0.4-0.1,0.5,0l3.5,2c0.2,0.1,0.3,0.3,0.3,0.5v4.7c0,0.3,0.3,0.6,0.6,0.6h2.2
        c0.3,0,0.6-0.3,0.6-0.6v-6.5c0-0.3-0.2-0.6-0.4-0.7l-5-2.9C22.8,17.4,22.7,17.3,22.7,17.1z M15.6,10.6l3.5,2
        c0.2,0.1,0.3,0.3,0.3,0.5v4c0,0.2-0.1,0.4-0.3,0.5l-3.5,2c-0.2,0.1-0.4,0.1-0.5,0l-3.5-2c-0.2-0.1-0.3-0.3-0.3-0.5v-4
        c0-0.2,0.1-0.4,0.3-0.5l3.5-2C15.2,10.5,15.4,10.5,15.6,10.6z"
        />
      </g>
    </svg>
    <app-progress-spinner [size]="'70px'"></app-progress-spinner>
  </div>
</div>
