import { Routes } from '@angular/router';

export const ROUTES_DASHBOARD_IMAGES: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./dashboard-image-upload.component').then(
        (m) => m.DashboardImageUploadComponent
      ),
  },
];
