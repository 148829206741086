import { inject } from '@angular/core';
import { Routes, ActivatedRouteSnapshot } from '@angular/router';
import { ObjectIdResolver } from '@app/shared/resolver/object-id.resolver';
import { RouteAuthGuard } from '@app/shared/services/route-guard.service';

export const ROUTES_WORKFLOW: Routes = [
  { path: '', redirectTo: 'cards', pathMatch: 'prefix' },
  {
    path: 'cards',
    loadComponent: () =>
      import('./pages/workflow-management/workflow-management.component').then(
        (m) => m.WorkflowManagementComponent
      ),
  },
  {
    path: 'list',
    loadComponent: () =>
      import('./pages/workflow-list/workflow-list.component').then(
        (m) => m.WorkflowListComponent
      ),
  },
  {
    path: 'add',
    loadComponent: () =>
      import('./pages/add-workflow/add-workflow.component').then(
        (m) => m.AddWorkFlowComponent
      ),
  },
  {
    path: ':objectId',
    loadComponent: () =>
      import('./pages/workflow-details/workflow-details.component').then(
        (m) => m.WorkflowDetailsComponent
      ),
    children: [
      { path: '', redirectTo: 'snapshot', pathMatch: 'prefix' },
      {
        path: 'snapshot',
        loadComponent: () =>
          import(
            './components/workflow-snapshot/workflow-snapshot.component'
          ).then((m) => m.WorkflowSnapshotComponent),
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
        ],
        data: { module: 'workflow-management', subModule: 'snapshot' },
      },
      {
        path: 'details',
        loadComponent: () =>
          import(
            './components/workflow-details/workflow-details.component'
          ).then((m) => m.WorkflowDetailsComponent),
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
        ],
        data: { module: 'workflow-management', subModule: 'details' },
      },
      {
        path: 'workflow-history',
        loadComponent: () =>
          import(
            './components/workflow-history/workflow-history.component'
          ).then((m) => m.WorkflowHistoryComponent),
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
        ],
        data: { module: 'workflow-management', subModule: 'workflow-history' },
      },
      {
        path: 'scope',
        loadComponent: () =>
          import('./components/workflow-scope/workflow-scope.component').then(
            (m) => m.WorkflowScopeComponent
          ),
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
        ],
        data: { module: 'workflow-management', subModule: 'scope' },
      },
      {
        path: 'condition',
        loadComponent: () =>
          import(
            './components/workflow-condition/workflow-condition.component'
          ).then((m) => m.WorkflowConditionComponent),
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
        ],
        data: { module: 'workflow-management', subModule: 'condition' },
      },
      {
        path: 'schedule',
        loadComponent: () =>
          import(
            './components/workflow-schedule/workflow-schedule.component'
          ).then((m) => m.WorkflowScheduleComponent),
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
        ],
        data: { module: 'workflow-management', subModule: 'schedule' },
      },
      {
        path: 'action',
        loadComponent: () =>
          import(
            './components/workflow-actions/workflow-actions.component'
          ).then((m) => m.WorkflowActionsComponent),
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
        ],
        data: { module: 'workflow-management', subModule: 'action' },
      },
      {
        path: 'resolve',
        loadComponent: () =>
          import(
            './components/workflow-actions/workflow-actions.component'
          ).then((m) => m.WorkflowActionsComponent),
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
        ],
        data: { module: 'workflow-management', subModule: 'resolve' },
      },
      {
        path: 'audit-log',
        loadComponent: () =>
          import('../../shared/components/audit-log/audit-log.component').then(
            (m) => m.AuditLogComponent
          ),
        resolve: { objectId: ObjectIdResolver },
        data: { module: 'workflow-management', subModule: 'audit-log' },
      },
      {
        path: 'debug',
        loadComponent: () =>
          import('./components/workflow-debug/workflow-debug.component').then(
            (m) => m.WorkflowDebugComponent
          ),
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
        ],
        data: { module: 'workflow-management', subModule: 'debug' },
      },
    ],
  },
];
