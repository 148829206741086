import { STORE_DEVICE_SCHEMA } from '../actions/action-types';

const initialState: AssetRelationships = {
  schemas: null,
};

interface AssetRelationships {
  schemas: Schemas;
}

interface Schemas {
  vendorName: string;
  base: any;
  displayTypes: any;
}

export function deviceSchemasReducer(
  state = initialState,
  action
): AssetRelationships {
  const schemas = {
    vendorName: action?.payload?.vendorName,
    base: action?.payload?.base,
    displayTypes: action?.payload?.displayTypes,
  };
  switch (action.type) {
    case STORE_DEVICE_SCHEMA:
      return {
        schemas: {
          ...state.schemas,
          [action.payload.deviceTypeId]: schemas,
        },
      };

    default: {
      return state;
    }
  }
}
