import {
  GET_ASSET_GROUPS_LOADING,
  STORE_ASSET_GROUPS,
  GET_ASSET_GROUPS_ERROR,
  DELETE_ASSET_GROUP,
  ADD_ASSET_GROUP,
  ASSET_COUNT_IN_ASSET_GROUP,
  UPDATE_ASSET_GROUP,
} from '../actions/action-types';

const initialState: AssetGroups = {
  assetGroups: null,
  assetGroupsLoading: false,
  assetGroupsHasError: false,
};

interface AssetGroups {
  assetGroups: any;
  assetGroupsLoading: boolean;
  assetGroupsHasError: boolean;
}

export function assetGroupReducer(state = initialState, action): AssetGroups {
  switch (action.type) {
    case GET_ASSET_GROUPS_LOADING:
      return {
        ...state,
        assetGroups: null,
        assetGroupsLoading: true,
        assetGroupsHasError: false,
      };

    case STORE_ASSET_GROUPS:
      return {
        ...state,
        assetGroups: action.payload,
        assetGroupsLoading: false,
        assetGroupsHasError: false,
      };

    case GET_ASSET_GROUPS_ERROR:
      return {
        ...state,
        assetGroups: null,
        assetGroupsLoading: false,
        assetGroupsHasError: true,
      };

    case DELETE_ASSET_GROUP:
      return {
        assetGroups: state.assetGroups.filter((g) => g.id !== action.payload),
        assetGroupsLoading: false,
        assetGroupsHasError: false,
      };

    case UPDATE_ASSET_GROUP:
      const index = state.assetGroups.findIndex(
        (p) => p.id === action.payload.id
      );
      state.assetGroups[index] = action.payload.data;
      return {
        assetGroups: state.assetGroups,
        assetGroupsLoading: false,
        assetGroupsHasError: false,
      };

    case ADD_ASSET_GROUP:
      return {
        ...state,
        assetGroups: [...state.assetGroups, ...action.payload],
      };

    case ASSET_COUNT_IN_ASSET_GROUP:
      return {
        ...state,
        assetGroups: state.assetGroups?.map((g) => {
          if (g.id === action.payload.id) {
            return {
              ...g,
              assetCount: action.payload.count,
            };
          } else {
            return g;
          }
        }),
      };

    default: {
      return state;
    }
  }
}
