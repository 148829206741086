import { SELECT_PEOPLE } from './../selectors/selectors';
import { Injectable } from '@angular/core';
import { AppHttpService } from '@app/shared/services/app-http.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../appState';
import {
  loadingPeopleData,
  loadPeopleFailure,
  loadPeopleSuccess,
} from '../actions/people.action';

@Injectable()
export class PeopleEffects {
  constructor(
    public actions$: Actions,
    private appHttpService: AppHttpService,
    private store: Store<AppState>
  ) {}

  loadPeopleData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadingPeopleData),
      withLatestFrom(this.store.select(SELECT_PEOPLE)),
      filter(([_action, people]) => people.data === null),
      switchMap(([_action, _people]) =>
        this.appHttpService.getPeople().pipe(
          map((data) => loadPeopleSuccess({ payload: data })),
          catchError((error) => of(loadPeopleFailure({ error })))
        )
      )
    )
  );
}
