import { environment } from '../environments/environment';

export const ProtectedRes = new Map<any, any>([
  [environment.baseUri + '/catalog/v3', [environment.scopeReplace]],
  [environment.baseUri + '/catalog/v3/tenants', [environment.scopeReplace]],
  [environment.baseUri + '/assets/v3', [environment.scopeReplace]],
  [environment.baseUri + '/assets/v3/schemas', [environment.scopeReplace]],
  [environment.baseUri + '/groups/v3', [environment.scopeReplace]],
  [
    environment.baseUri + '/assets/v3/socketdata/negotiate?negotiateVersion=1',
    [environment.scopeReplace],
  ],
  [
    environment.baseUri +
      '/connectors/v3/socketdata/negotiate?negotiateVersion=1',
    [environment.scopeReplace],
  ],
  [environment.baseUri + '/devices/v3', [environment.scopeReplace]],
  [environment.baseUri + '/configuration/v3', [environment.scopeReplace]],
  [environment.baseUri + '/locations/v3/hierarchy', [environment.scopeReplace]],
  [environment.baseUri + '/locations/v3/labels', [environment.scopeReplace]],
  [environment.baseUri + '/products/v3/hierarchy', [environment.scopeReplace]],
  [environment.baseUri + '/products/v3/labels', [environment.scopeReplace]],
  [environment.baseUri + '/auditlog/v3', [environment.scopeReplace]],
  [environment.baseUri + '/sites/v3', [environment.scopeReplace]],
  [environment.baseUri + '/geofence', [environment.scopeReplace]],
  [environment.baseUri + '/service/v3', [environment.scopeReplace]],
  [environment.baseUri + '/servicing/v3', [environment.scopeReplace]],
  [environment.baseUri + '/systemgroups/v3/asset', [environment.scopeReplace]],
  [environment.baseUri + '/masterData/v3/events', [environment.scopeReplace]],
  [environment.baseUri + '/directory/v3', [environment.scopeReplace]],
  [environment.baseUri + '/dashboards/v3', [environment.scopeReplace]],
  [environment.baseUri + '/reports/v3', [environment.scopeReplace]],
  [
    environment.baseUri + '/catalog/v3/roledefinitions',
    [environment.scopeReplace],
  ],
  [environment.baseUri + '/health/v3/', [environment.scopeReplace]],
  ['https://graph.microsoft.com/v1.0/me/transitiveMemberOf', ['user.read']],
  ['https://graph.microsoft.com/v1.0/me', ['user.read', 'user.readwrite']],
  [
    'https://graph.microsoft.com/v1.0/me/extensions',
    ['user.read', 'user.readwrite'],
  ],
  ['https://graph.microsoft.com/v1.0/groups', ['groupmember.read.all']],
  ['https://login.microsoftonline.com/', ['user.read', 'user.readwrite']],
  [environment.baseUri + '/rules', [environment.scopeReplace]],
  [environment.baseUri + '/devices/v3/counters/', [environment.scopeReplace]],
  [
    environment.baseUri + '/integration/v3/transportdockets',
    [environment.scopeReplace],
  ],
  [environment.baseUri + '/utilisation/v3/fuel', [environment.scopeReplace]],
  [environment.baseUri + '/utilisation/v3', [environment.scopeReplace]],
  [environment.baseUri + '/utilisation/v3/trips', [environment.scopeReplace]],
  [environment.baseUri + '/utilisation/v3', [environment.scopeReplace]],
  [environment.baseUri + '/utilities/v3', [environment.scopeReplace]],
  [environment.baseUri + '/search/v3/', [environment.scopeReplace]],
  [environment.baseUri + '/share/v3', [environment.scopeReplace]],
  [environment.baseUri + '/catalog/v3/logoimages', [environment.scopeReplace]],
  [environment.baseUri + '/drivers/v3', [environment.scopeReplace]],
  [environment.baseUri + '/organisation/v3', [environment.scopeReplace]],
  [environment.baseUri + '/connectors/v3', [environment.scopeReplace]],
  [environment.baseUri + '/workflows/v3', [environment.scopeReplace]],
  [environment.baseUri + '/users/v3', [environment.scopeReplace]],
  [environment.baseUri + '/apps/v3', [environment.scopeReplace]],
  [environment.baseUri + '/organisation/v3', [environment.scopeReplace]],
  [environment.baseUri + '/workorders/v3', [environment.scopeReplace]],
  [environment.baseUri + '/profiles/v3', [environment.scopeReplace]],
  [environment.baseUri + '/people/v3', [environment.scopeReplace]],
  [environment.baseUri + '/tasks/v3', [environment.scopeReplace]],
]);
