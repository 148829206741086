import {
  ADD_SECURITY_GROUP,
  ASSET_COUNT_IN_SECURITY_GROUP,
  UPDATE_SECURITY_GROUP,
} from './../actions/action-types';
import {
  DELETE_SECURITY_GROUP,
  GET_SECURITY_GROUPS_ERROR,
  GET_SECURITY_GROUPS_LOADING,
  STORE_SECURITY_GROUPS,
} from '../actions/action-types';

const initialState: SecurityGroups = {
  data: null,
  securityGroupsLoading: false,
  securityGroupsHasError: false,
};

interface SecurityGroups {
  data: any;
  securityGroupsLoading: boolean;
  securityGroupsHasError: boolean;
}

export function securityGroupReducer(
  state = initialState,
  action
): SecurityGroups {
  switch (action.type) {
    case GET_SECURITY_GROUPS_LOADING:
      return {
        ...state,
        data: null,
        securityGroupsLoading: true,
        securityGroupsHasError: false,
      };

    case STORE_SECURITY_GROUPS:
      return {
        ...state,
        data: action.payload,
        securityGroupsLoading: false,
        securityGroupsHasError: false,
      };

    case GET_SECURITY_GROUPS_ERROR:
      return {
        ...state,
        data: null,
        securityGroupsLoading: false,
        securityGroupsHasError: true,
      };

    case DELETE_SECURITY_GROUP:
      return {
        data: state.data.filter((g) => g.id !== action.payload),
        securityGroupsLoading: false,
        securityGroupsHasError: false,
      };

    case UPDATE_SECURITY_GROUP:
      const index = state.data.findIndex((p) => p.id === action.payload.id);
      state.data[index] = action.payload.data;
      return {
        data: state.data,
        securityGroupsLoading: false,
        securityGroupsHasError: false,
      };

    case ADD_SECURITY_GROUP:
      return {
        ...state,
        data: [...state.data, ...action.payload],
      };

    case ASSET_COUNT_IN_SECURITY_GROUP:
      return {
        ...state,
        data: state.data.map((g) => {
          if (g.id === action.payload.id) {
            return {
              ...g,
              assetCount: action.payload.count,
            };
          } else {
            return g;
          }
        }),
      };

    default: {
      return state;
    }
  }
}
