import {
  STORE_ASSET_HISTORY,
  STORE_ASSET_HISTORY_CLEAR,
} from '../actions/action-types';

const initialState: AssetHistory = {
  history: null,
};

interface AssetHistory {
  history: any;
}

export const assetFeatureKey = 'AssetHistory';

export function assetHistoryReducer(
  state = initialState,
  action
): AssetHistory {
  switch (action.type) {
    case STORE_ASSET_HISTORY:
      return {
        ...state,
        history: action.payload,
      };

    case STORE_ASSET_HISTORY_CLEAR:
      return {
        ...state,
        history: null,
      };

    default: {
      return state;
    }
  }
}
