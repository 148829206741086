import { Routes } from '@angular/router';
import { ObjectIdResolver } from '@app/shared/resolver/object-id.resolver';
import { ROUTES_DASHBOARD_IMAGES } from './components/dashboard-image-upload/dashboard-image-upload.routing';

export const ROUTES_DASHBOARD: Routes = [
  { path: '', redirectTo: 'cards', pathMatch: 'prefix' },
  {
    path: 'cards',
    loadComponent: () =>
      import('./pages/dashboards/dashboards.component').then(
        (m) => m.DashboardsComponent
      ),
  },
  {
    path: 'list',
    loadComponent: () =>
      import('./components/dashboard-list/dashboard-list.component').then(
        (m) => m.DashboardListComponent
      ),
  },
  {
    path: 'add',
    loadComponent: () =>
      import('./pages/add-dashboard/add-dashboard.component').then(
        (m) => m.AddDashboardComponent
      ),
  },
  {
    path: ':objectId',
    loadComponent: () =>
      import('./pages/dashboard-details/dashboard-details.component').then(
        (m) => m.DashboardDetailsComponent
      ),

    children: [
      { path: '', redirectTo: 'snapshot', pathMatch: 'prefix' },
      {
        path: 'snapshot',
        loadComponent: () =>
          import(
            './components/dashboard-snapshot/dashboard-snapshot.component'
          ).then((m) => m.DashboardSnapshotComponent),
      },
      {
        path: 'configuration',
        loadComponent: () =>
          import(
            './components/dashboard-details-wrap/dashboard-details-wrap.component'
          ).then((m) => m.DashboardDetailsWrapComponent),

        resolve: { objectId: ObjectIdResolver },
        data: { module: 'dashboards', subModule: 'configuration' },
      },
      {
        path: 'widgets',
        loadComponent: () =>
          import(
            './components/dashboard-widget-info/dashboard-widget-info.component'
          ).then((m) => m.DashboardWidgetInfoComponent),
      },
      {
        path: 'image-upload',
        loadChildren: () => ROUTES_DASHBOARD_IMAGES,
        resolve: { objectId: ObjectIdResolver },
        data: { module: 'dashboards', subModule: 'image-upload' },
      },
      {
        path: 'audit-log',
        loadComponent: () =>
          import('../../shared/components/audit-log/audit-log.component').then(
            (m) => m.AuditLogComponent
          ),
        resolve: { objectId: ObjectIdResolver },
        data: { module: 'dashboards', subModule: 'audit-log' },
      },
      {
        path: 'scope',
        loadComponent: () =>
          import('./components/dashboard-scope/dashboard-scope.component').then(
            (m) => m.DashboardScopeComponent
          ),
        resolve: { objectId: ObjectIdResolver },
        data: { module: 'dashboards', subModule: 'scope' },
      },
      {
        path: 'debug',
        loadComponent: () =>
          import('./components/dashboard-debug/dashboard-debug.component').then(
            (m) => m.DashboardDebugComponent
          ),
        data: { module: 'dashboards', subModule: 'debug' },
      },
    ],
  },
];
