import { SELECT_ASSETS_MASTER_DATA } from '../selectors/selectors';
import { Injectable } from '@angular/core';
import { AppHttpService } from '@app/shared/services/app-http.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../appState';
import {
  loadAssetsMasterData,
  loadAssetsMasterDataSuccess,
  loadAssetsMasterDataFailure,
} from '../actions/assets-master.action';

@Injectable()
export class AssetsMasterDataEffects {
  constructor(
    public actions$: Actions,
    private appHttpService: AppHttpService,
    private store: Store<AppState>
  ) {}

  AssetsMasterData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAssetsMasterData),
      withLatestFrom(this.store.select(SELECT_ASSETS_MASTER_DATA)),
      filter(([_action, assetMasterData]) =>  assetMasterData === null),
      switchMap(([_action, _assetMasterData]) =>
        this.appHttpService.getAssetsMasterData().pipe(
          map((data) => loadAssetsMasterDataSuccess({ payload: data })),
          catchError((error) => of(loadAssetsMasterDataFailure({ error })))
        )
      )
    )
  );
}
