import { Routes } from '@angular/router';

export const ROUTES_PROFILES: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./profiles.component').then((m) => m.ProfilesComponent),
    children: [
      {
        path: 'counters',
        loadComponent: () =>
          import('./custom-counters-list/custom-counters-list.component').then(
            (m) => m.CustomCountersListComponent
          ),
      },
      {
        path: 'counters/:id',
        loadComponent: () =>
          import(
            './custom-counter-details/custom-counter-details.component'
          ).then((m) => m.CustomCounterDetailsComponent),
      },
      {
        path: 'working-hours',
        loadComponent: () =>
          import('./working-hours-list/working-hours-list.component').then(
            (m) => m.WorkingHoursListComponent
          ),
      },
      {
        path: 'working-hours/:id',
        loadComponent: () =>
          import(
            './working-hours-profile-details/working-hours-profile-details.component'
          ).then((m) => m.WorkingHoursProfileDetailsComponent),
      },
      {
        path: 'asset-sharing',
        loadComponent: () =>
          import(
            './asset-sharing-profiles-list/asset-sharing-profiles-list'
          ).then((m) => m.AssetSharingProfilesListComponent),
      },
      {
        path: 'asset-sharing/:id',
        loadComponent: () =>
          import(
            './asset-sharing-profile-details/asset-sharing-profile-details.component'
          ).then((m) => m.AssetSharingProfileDetailsComponent),
      },
    ],
  },
];
