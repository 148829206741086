import { Routes } from '@angular/router';

export const ROUTES_SERVICING: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./components/servicing/servicing.component').then(
        (m) => m.ServicingComponent
      ),
    children: [
      { path: '', redirectTo: 'service-overview', pathMatch: 'prefix' },
      {
        path: 'service-overview',
        loadComponent: () =>
          import(
            './components/servicing-overview/servicing-overview.component'
          ).then((m) => m.ServicingOverviewComponent),
      },
      {
        path: 'time-to-service',
        loadComponent: () =>
          import('./components/time-to-service/time-to-service.component').then(
            (m) => m.TimeToServiceComponent
          ),
      },
      {
        path: 'service-schedules',
        loadComponent: () =>
          import(
            './components/service-schedules/service-schedules.component'
          ).then((m) => m.ServiceSchedulesComponent),
      },
      {
        path: 'service-programs',
        loadComponent: () =>
          import(
            './components/service-programs/service-programs.component'
          ).then((m) => m.ServiceProgramsComponent),
      },
      {
        path: 'service-reminders',
        loadComponent: () =>
          import(
            './components/service-reminders/service-reminders.component'
          ).then((m) => m.ServiceRemindersComponent),
      },
    ],
  },
];
