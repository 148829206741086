import {
  GET_PEOPLE_LOADING,
  GET_PEOPLE_ERROR,
  GET_PEOPLE_SUCCESS,
} from '../actions/action-types';

const initialState = {
  error: false,
  loading: false,
  data: null,
};

export function peopleReducer(state = initialState, action): any {
  switch (action.type) {
    case GET_PEOPLE_ERROR:
      return {
        error: true,
        loading: false,
        data: null,
      };
    case GET_PEOPLE_SUCCESS:
      return {
        error: false,
        loading: false,
        data: action.payload || [],
      };

    case GET_PEOPLE_LOADING:
      return {
        error: false,
        loading: true,
        data: null,
      };

    default: {
      return state;
    }
  }
}
