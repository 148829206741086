import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ObjectIdService {
  resolve(route: ActivatedRouteSnapshot): Observable<string> {
    return route.parent.params.objectId;
  }
}

export const ObjectIdResolver: ResolveFn<string> = (
  route: ActivatedRouteSnapshot
) => {
  return inject(ObjectIdService).resolve(route);
};
