import {
  STORE_LABEL_DATA,
  UPDATE_PRODUCT_LABELS,
  UPDATE_LOCATION_LABELS,
  UPDATE_OPERATIONAL_LABELS,
} from '../actions/action-types';

const initialState: LabelsData = {
  LocationLabels: null,
  ProductLabels: null,
  OperationalLabels: null
};

interface LabelsData {
  LocationLabels: any;
  ProductLabels: any;
  OperationalLabels: any;
}

export const assetFeatureKey = 'AssetData';

export function labelsReducer(state = initialState, action): LabelsData {
  switch (action.type) {
    case STORE_LABEL_DATA:{
      const locationData = {
        level1: {
          name: action?.payload?.find(
            (r) => r.property === 'locationHierarchyL1'
          )?.title,
        },
        level2: {
          name: action?.payload?.find(
            (r) => r.property === 'locationHierarchyL2'
          )?.title,
        },
        level3: {
          name: action?.payload?.find(
            (r) => r.property === 'locationHierarchyL3'
          )?.title,
        },
      };

      const productData = {
        level1: {
          name: action?.payload?.find(
            (r) => r.property === 'productHierarchyL1'
          )?.title,
        },
        level2: {
          name: action?.payload?.find(
            (r) => r.property === 'productHierarchyL2'
          )?.title,
        },
        level3: {
          name: action?.payload?.find(
            (r) => r.property === 'productHierarchyL3'
          )?.title,
        },
      };

      const operationalData = {
        level1: {
          name: action?.payload?.find(
            (r) => r.property === 'operationalHierarchyL1'
          )?.title,
        },
        level2: {
          name: action?.payload?.find(
            (r) => r.property === 'operationalHierarchyL2'
          )?.title,
        },
        level3: {
          name: action?.payload?.find(
            (r) => r.property === 'operationalHierarchyL3'
          )?.title,
        },
      };
      return {
        ...state,
        LocationLabels: locationData,
        ProductLabels: productData,
        OperationalLabels: operationalData,
      };
    }
    case UPDATE_LOCATION_LABELS:
      return {
        ...state,
        LocationLabels: action.payload,
      };

    case UPDATE_PRODUCT_LABELS:
      return {
        ...state,
        ProductLabels: action.payload,
      };

    case UPDATE_OPERATIONAL_LABELS:
      return {
        ...state,
        OperationalLabels: action.payload,
      };

    default: {
      return state;
    }
  }
}
