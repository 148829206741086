import { Injectable } from '@angular/core';
import { AppHttpService } from '@app/shared/services/app-http.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { withLatestFrom, switchMap, of, map, catchError, filter } from 'rxjs';
import {
    loadCounterData,
    loadCountersFailure,
    loadCountersSuccess,
} from '../actions/counter.action';
import { AppState } from '../appState';
import { SELECT_COUNTER } from '../selectors/selectors';

@Injectable()
export class CounterEffects {
  constructor(
    private actions$: Actions,
    private appHttpService: AppHttpService,
    private store: Store<AppState>
  ) {}
  CounterData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCounterData),
      withLatestFrom(this.store.select(SELECT_COUNTER)),
      filter(([_action, counterState]) => !counterState?.data),
      switchMap(() => {
        return this.appHttpService.getCustomCounters().pipe(
          map((counters) => loadCountersSuccess({ payload: counters })),
          catchError((error) => of(loadCountersFailure({ error })))
        );
      })
    )
  );
}
