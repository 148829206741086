import { Routes } from '@angular/router';

export const ROUTES_CONNECTORS: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./pages/connectors/connectors.component').then(
        (m) => m.ConnectorsComponent
      ),
    children: [
      { path: '', redirectTo: 'ingress', pathMatch: 'prefix' },
      {
        path: 'ingress',
        loadComponent: () =>
          import('./components/perspio-ingress/perspio-ingress.component').then(
            (m) => m.PerspioIngressComponent
          ),
      },
      {
        path: 'ingress/:id/info',
        loadComponent: () =>
          import('./pages/connector-details/connector-details.component').then(
            (m) => m.ConnectorDetailsComponent
          ),
      },
      {
        path: 'data',
        loadComponent: () =>
          import('./components/perspio-data/perspio-data.component').then(
            (m) => m.PerspioDataComponent
          ),
      },
      {
        path: 'data/:id/info',
        loadComponent: () =>
          import('./pages/connector-details/connector-details.component').then(
            (m) => m.ConnectorDetailsComponent
          ),
      },
      {
        path: 'actions',
        loadComponent: () =>
          import('./components/perspio-actions/perspio-actions.component').then(
            (m) => m.PerspioActionsComponent
          ),
      },
      {
        path: 'actions/:id/info',
        loadComponent: () =>
          import('./pages/connector-details/connector-details.component').then(
            (m) => m.ConnectorDetailsComponent
          ),
      },
      {
        path: 'webhooks',
        loadComponent: () =>
          import(
            './components/perspio-webhooks/perspio-webhooks.component'
          ).then((m) => m.PerspioWebhooksComponent),
      },
      {
        path: 'webhooks/:id/info',
        loadComponent: () =>
          import('./pages/connector-details/connector-details.component').then(
            (m) => m.ConnectorDetailsComponent
          ),
      },
    ],
  },
];
