import {
  GET_SITES_ERROR,
  GET_SITES_SUCCESS,
  GET_SITES_LOADING,
  ADD_SITE,
  DELETE_SITE,
} from '../actions/action-types';

const initialState = {
  error: false,
  loading: false,
  data: null,
};

export function sitesReducer(state = initialState, action): any {
  switch (action.type) {
    case GET_SITES_ERROR:
      return {
        error: true,
        loading: false,
        data: [],
      };

    case GET_SITES_SUCCESS:
      return {
        error: false,
        loading: false,
        data: action.payload || [],
      };

    case GET_SITES_LOADING:
      return {
        error: false,
        loading: true,
        data: [],
      };

    case ADD_SITE:
      return {
        error: false,
        loading: false,
        data: [...state.data, ...action.payload],
      };

    case DELETE_SITE:
      const newSiteList = [
        ...state.data.filter((g) => g.id !== action.payload),
      ];
      return {
        error: false,
        loading: false,
        data: newSiteList,
      };

    default: {
      return state;
    }
  }
}
