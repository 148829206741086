import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { AppState } from '../store/appState';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserDataResolver  {
  userDataSelector = (state: AppState) => state.UserData;
  constructor(private store: Store) {}

  resolve() {
    return this.store
      .select(this.userDataSelector)
      .pipe(
        filter(
          (storeResponse) => storeResponse?.resolvedLocalisationPreferences
        )
      );
  }
}
