import {
  STORE_ASSET_DATA,
  STORE_ASSET_DATA_CLEAR,
} from '../actions/action-types';

const initialState: AssetData = {
  liveData: null,
};

interface AssetData {
  liveData: any;
}

export const assetFeatureKey = 'AssetData';

export function assetReducer(state = initialState, action): AssetData {
  switch (action.type) {
    case STORE_ASSET_DATA:
      return {
        ...state,
        liveData: action.payload,
      };

    case STORE_ASSET_DATA_CLEAR:
      return {
        ...state,
        liveData: null,
      };

    default: {
      return state;
    }
  }
}
