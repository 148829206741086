import {
  STORE_TENANT_CONFIGURATION,
  UPDATE_TENANT_CONFIG,
} from '../actions/action-types';

const initialState = null;

export function tenantConfigurationReducer(state = initialState, action): any {
  switch (action.type) {
    case STORE_TENANT_CONFIGURATION:
      return {
        ...action.payload.configs,
      };

    case UPDATE_TENANT_CONFIG:
      return {
        ...state,
        ...action.payload,
      };

    default: {
      return state;
    }
  }
}
