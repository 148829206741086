import { SELECT_SERVICING_DATA } from '../selectors/selectors';
import { Injectable } from '@angular/core';
import { AppHttpService } from '@app/shared/services/app-http.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../appState';
import {
  loadServicingData,
  loadServicingDataSuccess,
  loadServicingDataFailure,
} from '../actions/servicing.action';

@Injectable()
export class ServicingDataEffects {
  constructor(
    public actions$: Actions,
    private appHttpService: AppHttpService,
    private store: Store<AppState>
  ) {}

  ServicingData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadServicingData),
      withLatestFrom(this.store.select(SELECT_SERVICING_DATA)),
      filter(([_action, servicingData]) => {
        return servicingData === null;
      }),
      switchMap(([_action, _servicingData]) =>
        this.appHttpService.getServicingData().pipe(
          map((data) => loadServicingDataSuccess({ payload: data })),
          catchError((error) => of(loadServicingDataFailure({ error })))
        )
      )
    )
  );
}
