// Asset-entity.effects.ts

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { mergeMap } from 'rxjs/operators';
import { AssetActionTypes, UpsertAssetsSuccess } from './assets-entity.action';

@Injectable()
export class AssetEffects {
  upsertAssets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetActionTypes.UpsertAssets),
      mergeMap((action: any) => {
        return [new UpsertAssetsSuccess({ assets: action.payload.assets })];
      })
    )
  );

  constructor(private actions$: Actions, private store: Store) {}
}
