import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { filter, map, Observable } from 'rxjs';
import { AppHttpService } from '../services/app-http.service';
import { AuthService } from '../services/auth.service';
import { LandingPageNavigationService } from '@app/shared/services/landing-page-navigation.service';
import { AppStoreService } from '../services/app-store.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard {
  constructor(
    private authService: AuthService,
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private router: Router,
    private appHttpService: AppHttpService,
    private landingPageNavigationService: LandingPageNavigationService,
    private appStoreService: AppStoreService
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .pipe(
        map(() => {
          //Getting the user from the service or localstorage, this needs accesstoken first, so msal need to be completed
          const localUserData = this.authService.getUser();
          const msalConfirmedUser =
            this.msalService.instance.getAllAccounts()[0];
          if (msalConfirmedUser && localUserData?.tid) {
            if (this.authService.login() && this.authService?.user?.tid) {
              this.appStoreService.getUserData();
              this.landingPageNavigationService.navigate();
            }
            return false;
          } else if (!msalConfirmedUser) {
            localStorage.clear();
            sessionStorage.clear();
            return true;
          } else if (msalConfirmedUser && !localUserData) {
            this.fetchTennantInfo().subscribe();
            return false;
          } else if (msalConfirmedUser && !localUserData.tid) {
            this.router.navigate(['/tenant-selection']);
            return false;
          }
        })
      );
  }

  fetchTennantInfo(): any {
    return this.appHttpService.fetchTennantInfo().pipe(
      map((data) => {
        this.setUser(data);
        this.checkForTenants();
        this.authService.showLoader$.next(false);
        return data;
      })
    );
  }

  setUser(data): void {
    this.authService.setUser(
      this.authService.currentUser.idTokenClaims,
      data,
      this.authService.currentUser.localAccountId
    );
    this.authService.login();
  }

  checkForTenants(): void {
    this.authService.checkForTenants();
  }
}
